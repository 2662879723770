import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import SecondHeader from '../components/headers/SecondHeader'
import EventsList from '../components/events/EventsList';
import { useContextState } from '../context/Context';
import LoginModal from '../components/modals/LoginModal';
import Footer from '../components/footers/Footer'
import ApplicationBar from "../components/shared/ApplicationBar";
import { useEventApi } from "../store";
import backgroundImg from '../backgroundImg.jpeg'

const MyEvents = () => {
  const { accountCTX, eventsCTX } = useContextState();
  const { signedIn } = accountCTX
  const {
    setEventPageType,
    allEventsState: { myEventsUpcoming, myEventsCompleted, myEventsCancelled },
    eventsState: { statusQuery },
    eventsDispatch,
  } = eventsCTX;

  const { getMyEvents } = useEventApi();
  const navigate = useNavigate();

  document.title = `FestiShare: My Events`

  const [isInitialLoadPage, setIsInitialLoadPage] = useState(true);
  const [isInitialLoadCompleted, setIsInitialLoadCompleted] = useState(true);
  const [isInitialLoadCancelled, setIsInitialLoadCancelled] = useState(true);
  const [isInitialLoadUpcoming, setIsInitialLoadUpcoming] = useState(true);

  const [currentPageView, setCurrentPageView] = useState<string>('loader');

  const [resultIndexCompleted, setResultIndexCompleted] = useState<number>(1);
  const [resultIndexCancelled, setResultIndexCancelled] = useState<number>(1);
  const [resultIndexUpcoming, setResultIndexUpcoming] = useState<number>(1);

  const [hasMoreCompleted, setHasMoreCompleted] = useState<boolean>(true);
  const [hasMoreCancelled, setHasMoreCancelled] = useState<boolean>(true); 
  const [hasMoreUpcoming, setHasMoreUpcoming] = useState<boolean>(true);  

  const stateInfo = {
    'completed': [myEventsCompleted, isInitialLoadCompleted, setIsInitialLoadCompleted, resultIndexCompleted, setResultIndexCompleted, hasMoreCompleted, setHasMoreCompleted],
    'cancelled': [myEventsCancelled, isInitialLoadCancelled, setIsInitialLoadCancelled, resultIndexCancelled, setResultIndexCancelled, hasMoreCancelled, setHasMoreCancelled],
    'upcoming': [myEventsUpcoming, isInitialLoadUpcoming, setIsInitialLoadUpcoming, resultIndexUpcoming, setResultIndexUpcoming, hasMoreUpcoming, setHasMoreUpcoming]
  }

  const transformStatus = (statusQuery:string) => {
    return !isInitialLoadPage? (statusQuery as keyof typeof stateInfo): 'upcoming'
  }

  var stateInfoValue = stateInfo[ transformStatus(statusQuery) as keyof typeof stateInfo ]
  var isInitialLoad = stateInfoValue[1]
  var resultIndex = stateInfoValue[3]
  var hasMore = stateInfoValue[5]

  

  const fetchEvents = async () => {
    if (currentPageView!=='loader'){setCurrentPageView('default')}
    const setResultIndex = stateInfoValue[4]
    const setHasMore = stateInfoValue[6]
    const setIsInitialLoad = stateInfoValue[2]

    const apiCall = await getMyEvents(statusQuery, resultIndex)
    if (apiCall && (apiCall.results.length > 0)) {
      setResultIndex(apiCall.next ? resultIndex + 1 : resultIndex);
      setHasMore(apiCall.next? true: false);
      if (isInitialLoad){setIsInitialLoad(false);}
    }
    else {setHasMore(false)}
    if (currentPageView==='loader'){setCurrentPageView('default')}
  };

  const loadMore = async () => {
    try {await fetchEvents();} 
    catch (error) { console.error('Error loading more events:', error);}
  };

  useEffect(()=>{
    setIsInitialLoadPage(false);
    setEventPageType('myevent');
  }, [])

  useEffect(() => {
    stateInfoValue = stateInfo[transformStatus(statusQuery) as keyof typeof stateInfo ]
    isInitialLoad = stateInfoValue[1]
    hasMore = stateInfoValue[5]
    resultIndex = stateInfoValue[3]
    if (isInitialLoad){fetchEvents(); }
  }, [ statusQuery ])


  return (
    <div>
      {!signedIn? 
        <main className="flex isolate min-h-full">
          <img
            src={backgroundImg}
            alt=""
            className="absolute inset-0 -z-10 h-full w-full object-cover object-top"
          />
          <div className='mt-50'>
            <LoginModal close={true} closeButton={() => navigate('/')} back={() => navigate('/')}/>
          </div>
        </main>
          :
        <div>
          <SecondHeader headerType='myevents' />
          <EventsList 
            events={stateInfo[transformStatus(statusQuery) as keyof typeof stateInfo ][0]}
            loadMore={loadMore}
            hasMore={hasMore}
          />
        </div>
      } 
      <Footer />
      <ApplicationBar />
    </div>
  )
}

export default MyEvents