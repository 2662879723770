import { Fragment, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useContextState } from '../../context/Context'
import { useMessagesApi } from '../../store'
import { useShared } from '../../store'
import ListWithIcons from '../shared/ListWithIcons'
import { useGlobalModalContext, MODAL_TYPES } from '../modals/GlobalModal'
import Loader from '../shared/Loader'
import { Modal, Button } from 'flowbite-react'
import EventDetails from '../events/eventDetails'
import Messages from './Messages'
import SecondHeader from '../headers/SecondHeader'
import ApplicationBar from '../shared/ApplicationBar'
import NewSupportMessage from './NewSupportMessage'

interface Props{
    type: string,
    modalType: string,
    setModalType: React.Dispatch<React.SetStateAction<string>>,
    showNewModal:boolean,
    setShowNewModal: React.Dispatch<React.SetStateAction<boolean>>,
    
}

// export default function InboxList() 
const InboxList: React.FC<Props> = ({ type, modalType, setModalType, showNewModal, setShowNewModal }) =>{
    const navigate = useNavigate();

    const { showModal } = useGlobalModalContext();
    const [openModal, setOpenModal] = useState<string | undefined>();
    const props = { openModal, setOpenModal,  };
    const { inboxCTX } = useContextState();

    const {
        inboxState: { inboxState, privateInboxState, eventsInboxState, supportInboxState },
        inboxFilterState : { sort, byRating, searchQuery, statusQuery} ,
        dispatchInboxState
    } = inboxCTX;

    const { 
        getInboxes,
    } = useMessagesApi();

    const { 
        dateDifferenceSign,
        getUniqueElementsOfArray 
    } = useShared();

    // const [showNewModal, setShowNewModal] = useState<boolean>(false);
    // const [modalType, setModalType] = useState<string>('');
    const [eventId, setEventId] = useState<string>('');
    const [inboxItem, setInboxItem] = useState<any>();

    const [currentPageView, setCurrentPageView] = useState<string>('loader');
    const [inboxList, setInboxList] = useState<any[]>([]);

    const profileModal = (profileId:string) => {
        props.setOpenModal('initial-focus');
        showModal(MODAL_TYPES.PROFILE_MODAL, { title: "Create instance form", confirmBtn: "Close", profileType:'guest', profileId:profileId });
    };

    const [itemOptions, setItemOptions] = useState<any[]>([{'name':'View profile', 'callBack': profileModal, 'callBackId': 'user'}]);

    
    const eventModal = (eventId:string) =>{
        setEventId(eventId);
        setModalType('Event')
        setShowNewModal(true)
    }


    const seeMessages = (messageId:string) =>{
        navigate('/messages/' + messageId)
    }

    
    const getInboxesList = (type:string) => {
        getInboxes({type:type}).then(apiCall => { 
            if (apiCall){ setInboxList(apiCall.data); }else{ setInboxList([]); }
            if (currentPageView === 'loader'){ setCurrentPageView('default')}
        });
    }

    const transformInboxList = (inboxesToList:Event[]) => {
        
        let sortedInboxes = inboxesToList;
    
        if (sort) {
            sortedInboxes = sortedInboxes.sort((a:any, b:any) =>
                sort === "soonToLater" ? dateDifferenceSign(a.last_messages_time, b.last_messages_time) : dateDifferenceSign(b.last_messages_time, a.last_messages_time)
            );
        }
    
        if (byRating) {
          sortedInboxes = sortedInboxes.filter(
            (event:any) => event.ratings >= byRating
          );
        }
    
        // if (searchQuery) {
        //     sortedInboxes = sortedInboxes.filter((event:any) => 
        //         event.type.toLowerCase().includes(searchQuery)
        //     );
        // }
  
        // if (statusQuery) {
        //   sortedInboxes = sortedInboxes.filter((event:any) => 
        //     event.status.toLowerCase().includes(statusQuery)
        //   );
        // }

        const uniqueInboxes = getUniqueElementsOfArray(sortedInboxes)
  
        return uniqueInboxes;
    };

    useEffect(() => {
        if ((type === 'private') && (inboxState === 'private')){
            setCurrentPageView('loader');
            // if (privateInboxState){setInboxList(privateInboxState);}
            // else {getInboxesList('private');} 
            getInboxesList('private')
            setItemOptions([{'name':'View profile', 'callBack': profileModal, 'callBackId': 'subject'}, {'name':'Message', 'callBack': profileModal, 'callBackId': 'user'}])
            setCurrentPageView('default')
        }
        else if ((type === 'private') && (inboxState === 'event')){
            setCurrentPageView('loader')
            // if (eventsInboxState){setInboxList(eventsInboxState);}
            // else {getInboxesList('event');} 
            getInboxesList('event');
            setItemOptions([{'name':'View event', 'callBack': eventModal, 'callBackId': 'event'}, {'name':'Message', 'callBack': profileModal, 'callBackId': 'user'}])
            setCurrentPageView('default')
        }
        else if ((type === 'support') && (inboxState === 'support')){
            setCurrentPageView('loader')
            // if (supportInboxState){setInboxList(supportInboxState);}
            // else {getInboxesList('support');} 
            getInboxesList('support');
            setItemOptions([])
            setCurrentPageView('default')
        }
    }, [inboxState]);

    
    // console.log(inboxState)
    // console.log(inboxList)
    return (
        <div className="bg-white mt-20">
            {(currentPageView === 'default') && (inboxList.length > 0)?
                <div>
                    {/* <SecondHeader headerType='messages' /> */}
                    <ListWithIcons
                        titleId='subject'
                        callBackId='id'
                        callBack={seeMessages} 
                        itemOptions={itemOptions} 
                        listItems={transformInboxList(inboxList)} 
                        subtitleId='count'
                        subtitleName='Unread'
                    />
                </div>
                :
                <div >
                    {(currentPageView === 'default') && (inboxList.length === 0)?
                        <div>
                            <h6 className='mt-12' >Empty</h6>
                        </div>:null
                    }
                </div>
            }

            {/* {(currentPageView === 'messages') && (inboxList.length > 0)?
                <div className=''>
                    <Messages inboxItem={inboxItem[0]} backButton={() => {return setCurrentPageView('default')} }/>
                </div>
                :
                <div className='flex items-center mt-6'>
                    {(currentPageView === 'messages') && (inboxList.length === 0)?
                        <h6 >Empty</h6>:null
                    }
                </div>
            } */}

            { (currentPageView === 'loader')? <div className='mt-12'><Loader /></div>: null }

            <Modal show={showNewModal} size="3xl" onClose={() => setShowNewModal(false)}>
                <Modal.Header>{modalType}</Modal.Header>
                <Modal.Body>
                    {(modalType === 'Event')?
                        <EventDetails eventId={eventId} />:null
                    }

                    {(modalType === 'Support Message')?
                        <NewSupportMessage callBack={()=>{getInboxesList('support'); return setShowNewModal(false)}}/>:null
                    }
                    
                </Modal.Body>
            </Modal>

            {(currentPageView !== 'messages')? 
                <ApplicationBar />:null
            }

        </div>
    )
}


export default InboxList