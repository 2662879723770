import { useEffect, useState } from 'react';
import './App.css';
import Header from './components/headers/Header';
import Home from './pages/Home';
import CookiesNotice from './components/shared/CookiesNotice';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// import ApplicationBar from './components/shared/ApplicationBar';
import Event from './pages/Event'
import MyTickets from './pages/MyTickets';
import MyEvents from './pages/MyEvents';
import Social from './pages/Social';
// import Footer from './components/footers/Footer';
import Transactions from './pages/Transactions';
import Blogs from './pages/Blogs';
import Inbox from './pages/Inbox';
import MessagesPage from './pages/MessagesPage';
import NotFound from './pages/NotFound';
import NotificationHandler from './components/notification/Notification';
import Pwa from './components/shared/Pwa';
import TicketsPage from './pages/TicketsPage';
import ProfilePage from './pages/ProfilePage';
import MyCalendar from './pages/MyCalendar';
import ReloadButton from './components/shared/ReloadButton';
import { useNotificationApi } from './store';
import { useContextState } from './context/Context';



function App() {
  const [isApp, setIsApp] = useState<boolean>(false)

  const { getFriendRequestsCount, getPrivateAndEventsInboxUnreadMessagesCount } = useNotificationApi()

  const { accountCTX } = useContextState();
  const { signedIn } = accountCTX

  useEffect(()=>{
    getFriendRequestsCount();
    getPrivateAndEventsInboxUnreadMessagesCount();
  }, [])

  useEffect(()=>{
    if ((typeof(Storage) !== "undefined") && (typeof localStorage.getItem("app") !== "undefined")) {
      if ((localStorage.getItem("app") === 'yes') && (!isApp)){
        setIsApp(true);
      }
      else if (isApp){ setIsApp(false)}
    }
  },[])

  
  

  return (
    <BrowserRouter>
      <Header />
      <ReloadButton />
      <Routes>
          <Route path='/' element={<Home isApp={isApp}/>}></Route>
          <Route path='/app' element={<Home isApp={isApp}/>}></Route>
          <Route path='/web' element={<Home isApp={false}/>}></Route>
          <Route path='/blog' element={<Blogs />}></Route>
          <Route path='/calendar' element={<MyCalendar />}></Route>
          <Route path='/transactions' element={<Transactions />}></Route>
          <Route path='/event' element={<Event />}></Route>
          <Route path='/event/:eventId' element={<Event />}></Route>
          <Route path='/mytickets' element={<MyTickets />}></Route>
          <Route path='/tickets/:ticketId' element={<TicketsPage />}></Route>
          <Route path='/profile/:profileId' element={<ProfilePage />}></Route>
          <Route path='/myevents' element={<MyEvents />}></Route> 
          <Route path='/social' element={<Social />}></Route>
          <Route path='/inbox' element={<Inbox type='private'/>}></Route>
          <Route path='/messages/:inboxId' element={<MessagesPage />}></Route>
          <Route path='/support_messages' element={<Inbox type='support'/>}></Route>
          <Route path='*' element={<NotFound />}></Route>
      </Routes>

      { signedIn?
        <NotificationHandler />
        : null
      }
      
      <Pwa />

      
      {/* <CookiesNotice /> */}
      {/* <Footer /> */}
      {/* <ApplicationBar /> */}
      
    </BrowserRouter>
  );  
}

export default App;
