import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useContextState } from '../../context/Context'
import { useSocialApi } from '../../store'
import { useShared } from '../../store'
import ListWithIcons from '../shared/ListWithIcons'
import { useGlobalModalContext, MODAL_TYPES } from '../modals/GlobalModal'
import Loader from '../shared/Loader'
import { Modal } from 'flowbite-react'
import EventDetails from '../events/eventDetails'
import InfiniteScroll from 'react-infinite-scroll-component';
import ListWithIconsElement from '../shared/ListWithIconsElement'


function classNames(...classes:any[]) {
  return classes.filter(Boolean).join(' ')
}

interface Props{
    people: any[];
    loadMore: ()=> void;
    hasMore: boolean;
    socialState: string;
  }

  const PeopleList: React.FC<Props> = ({people, loadMore, hasMore, socialState}) => {
    const navigate = useNavigate();
    const { showModal } = useGlobalModalContext();
    const [openModal, setOpenModal] = useState<string | undefined>();
    const props = { openModal, setOpenModal,  };

    const profileModal = (profileId:string) => {
        props.setOpenModal('initial-focus');
        showModal(MODAL_TYPES.PROFILE_MODAL, { 
            title: "Create instance form", 
            confirmBtn: "Close", 
            profileType:'guest', 
            profileId:profileId 
        });
    };

    const [showEventModal, setShowEventModal] = useState<boolean>(false);
    const [eventId, setEventId] = useState<string>('');
    const [itemOptions, setItemOptions] = useState<any[]>([{'name':'View profile', 'callBack': profileModal, 'callBackId': 'user'}]);

    const seeMessages = (messageId:string) =>{
        navigate('/messages/' + messageId)
    }

    const eventModal = (eventId:string) =>{
        setEventId(eventId);
        setShowEventModal(true);
    }

    useEffect(()=>{
        if (socialState === 'friends'){
            setItemOptions([{'name':'View profile', 'callBack': profileModal, 'callBackId': 'user'}, {'name':'Message', 'callBack': seeMessages, 'callBackId': 'inbox_id'}])
        } 
        else if (socialState === 'requestsReceived' || socialState === 'requestsSent'){ 
            setItemOptions([{'name':'View profile', 'callBack': profileModal, 'callBackId': 'user'}])
        }
        else if (socialState === 'potentialFriends'){
            setItemOptions([{'name':'View profile', 'callBack': profileModal, 'callBackId': 'user'}, {'name':'View event', 'callBack': eventModal, 'callBackId': 'eventId'}])
        }
        else {
            setItemOptions([{'name':'View profile', 'callBack': profileModal, 'callBackId': 'user'}, {'name':'Message', 'callBack': profileModal, 'callBackId': 'user'}])
        }
    }, [socialState])
    

    

    return (
        <div className="mt-16 bg-white">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <ul role="list" className="divide-y divide-gray-100"> 
                    
                    <InfiniteScroll
                        className="mx-auto max-w-7xl px-6 lg:px-8"
                        dataLength={people.length}
                        next={loadMore}
                        hasMore={hasMore}
                        loader={<Loader />}
                        endMessage={<p className='mt-20 mb-20'>Nothing to list ...</p>}
                    >
                        
                        {(people).map((item:any, index:number) => (
                            <div key={index} className="border-b">
                                <ListWithIconsElement 
                                    key={index}
                                    titleId='user'
                                    callBackId='user' 
                                    callBack={profileModal} 
                                    itemOptions={itemOptions} 
                                    item={item} 
                                    subtitleId={(socialState === 'friends')? '': "eventName"}
                                    subtitleName = {(socialState === 'friends')? '': "Common Event"}
                                ></ListWithIconsElement>
                            </div>
                        ))}
                         
                    </InfiniteScroll>
                </ul> 



                <Modal show={showEventModal} size="3xl" onClose={() => setShowEventModal(false)}>
                    <Modal.Header>Event</Modal.Header>
                    <Modal.Body>
                        <EventDetails eventId={eventId} />
                    </Modal.Body>
                </Modal>
            </div>

        </div>
    )
}


export default PeopleList