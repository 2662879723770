import { useState } from "react"
import config from "../config";
import Cookies from 'js-cookie';

// const _ = require("lodash");

export const useShared = () => {
    const [loader, setLoader] = useState({ display: true, message: "Chargemenet...", timeout: 2 });
    const [alert, setAlert ] = useState({ display: false, title: "Alert",  message: "Ceci est un alert",  buttons: [{ title:"Continuer", action: () => console.log("clicked!")}] })

    const displayLoader = (message: string, timeout: number) => {
        setLoader({ display: true, timeout, message})
    }

    const dismissLoader = () => {
        setLoader({ display: false, timeout: 15, message: "Chargement..."})
    }

    const displayAlert = (data: {
        title: string;
        message: string;
        buttons: { title: string; action: () => void  }[]
    }) => {
        setAlert({display: true, ...data})
    }

    const dismissAlert = () => {
        setAlert({display: false, title: "", message: '', buttons: []})
    }

    const titleCase = (string:string) => {
        
        if (typeof string !== 'undefined'){
            const words = string.split(" ");
            for (let i = 0; i < words.length; i++) {
                if (words[i]){words[i] = words[i][0].toUpperCase() + words[i].substr(1);}
            }
            return words.join(" ");
        }

        return ''
        
    }

    const isThisAPastTime = (timeToCheck:string) =>{
        const today = new Date().getTime();
        const endTimeStr = timeToCheck
        const endTime = new Date(endTimeStr).getTime();
        const diff = today - endTime
        if (diff > 0){
        return true
        }
        return false
    }


    const dateDifferenceSign = (firstDate:string, secondDate:string) =>{
        const a = new Date(firstDate).getTime();
        const b = new Date(secondDate).getTime();
        return a - b
    }

    const secondsSinceThisDate = (timeToCheck:string) =>{
        const timeDifference = (new Date().getTime()) - (new Date(timeToCheck).getTime())
        // console.log('diff: ', timeDifference/1000)
        return (timeDifference / 1000)
    }


    const setTokens = (token:string, refreshToken:string) => {
        var inSixtyMinutes = new Date(new Date().getTime() + (60 * 60 * 1000));
        // var inSixtyMinutes = new Date(new Date().getTime() + (15 * 1000));
        // console.log('url: ', config.backendSubDomain)
        Cookies.set("token", token, { domain: config.backendSubDomain, expires: inSixtyMinutes, sameSite: 'strict' })
        Cookies.set("refreshToken", refreshToken, { domain: config.backendSubDomain,  expires: 7, sameSite: 'strict' })
    }


    function getUniqueElementsOfArray(array:any[]) {
        const uniqueElements = Array.from(new Set(array.map(a => a.id))).map(id => {
            return array.find(a => a.id === id)
        });
        return uniqueElements
    }




    return { 
        loader,
        displayLoader, 
        dismissLoader,  
        alert, 
        displayAlert, 
        dismissAlert, 
        titleCase, 
        isThisAPastTime, 
        dateDifferenceSign, 
        secondsSinceThisDate, 
        setTokens,
        getUniqueElementsOfArray
    }
}