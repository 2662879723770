import  { useState, useReducer, useEffect } from 'react';
import { inboxReducer, peopleFilterReducer } from './Reducers';


export const useInboxContext = () => {

  const inboxStateStorageValue = window.localStorage.getItem("inboxState");
  const privateInboxStateStorageValue = window.localStorage.getItem("privateInboxState");
  const eventsInboxStateStorageValue = window.localStorage.getItem("eventsInboxState");
  const supportInboxStateStorageValue = window.localStorage.getItem("supportInboxState");
  const [inboxState, dispatchInboxState] = useReducer(inboxReducer, { 
    inboxState: (inboxStateStorageValue && inboxStateStorageValue !== 'undefined')? inboxStateStorageValue : 'private',
    privateInboxState: (privateInboxStateStorageValue && privateInboxStateStorageValue !== 'undefined')? JSON.parse(privateInboxStateStorageValue) : '',
    eventsInboxState: (eventsInboxStateStorageValue && eventsInboxStateStorageValue !== 'undefined')? JSON.parse(eventsInboxStateStorageValue) : '',
    supportInboxState: (supportInboxStateStorageValue && supportInboxStateStorageValue !== 'undefined')? JSON.parse(supportInboxStateStorageValue) : '',
  }); 

  useEffect(() => {
    window.localStorage.setItem("inboxState", inboxState.inboxState);
  }, [inboxState.inboxState]);

  // useEffect(() => {
  //   window.localStorage.setItem("privateInboxState", JSON.stringify(inboxState.privateInboxState));
  // }, [inboxState.privateInboxState]);

  // useEffect(() => {
  //   window.localStorage.setItem("eventsInboxState", JSON.stringify(inboxState.eventsInboxState));
  // }, [inboxState.eventsInboxState]);

  // useEffect(() => {
  //   window.localStorage.setItem("supportInboxState", JSON.stringify(inboxState.supportInboxState));
  // }, [inboxState.supportInboxState]);



  // ......................FILTER
  const [inboxFilterState, inboxFilterDispatch] = useReducer(peopleFilterReducer, {
    sort: 'soonToLater',
    byStock: false,
    byFastDelivery: false,
    byRating: 0,
    searchQuery: "",
    statusQuery: "upcoming",
    ticketStatusQuery: "active",
    ticketAndEventStatusQuery: {"event":"upcoming", "ticket":"active"}
  });
  
  return { inboxState, dispatchInboxState, inboxFilterState, inboxFilterDispatch }

}