
import { useParams, useNavigate } from 'react-router-dom'
import { useContextState } from '../context/Context';
import LoginModal from '../components/modals/LoginModal';
import Messages from '../components/messages/Messages';
import backgroundImg from '../backgroundImg.jpeg'


const MessagesPage = () => {
  const { accountCTX } = useContextState();
  const { signedIn } = accountCTX

  const navigate = useNavigate();

  const { inboxId } = useParams()

  return (
    <div >
      {!signedIn? 
        <main className="flex isolate min-h-full">
          <img
            src={backgroundImg}
            alt=""
            className="absolute inset-0 -z-10 h-full w-full object-cover object-top"
          />
          <div className='mt-50'>
            <LoginModal close={true} closeButton={() => navigate('/')} back={() => navigate('/')}/>
          </div>
        </main>
        :
        <div>
            <Messages inboxItem={inboxId} backButton={() => {return navigate(-1)} }/>
        </div>
      } 
    </div>
  )
}

export default MessagesPage