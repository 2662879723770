
import { useContextState } from '../context/Context';
import Footer from '../components/footers/Footer'
import ApplicationBar from '../components/shared/ApplicationBar';
import Calendar from '../components/calendar/Calendar';
import LoginModal from '../components/modals/LoginModal';
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import backgroundImg from '../backgroundImg.jpeg'

const MyCalendar = () => {
  const { accountCTX, eventsCTX} = useContextState();
  const { signedIn } = accountCTX
  const { setEventPageType } = eventsCTX;

  const navigate = useNavigate();

  document.title = `FestiShare: My Calendar`

  useEffect(()=>{setEventPageType('');}, [])


  return (
    <div >
      {!signedIn? 
        <main className="flex isolate min-h-full">
          <img
            src={backgroundImg}
            alt=""
            className="absolute inset-0 -z-10 h-full w-full object-cover object-top"
          />
          <div className='mt-50'>
            <LoginModal close={true} closeButton={() => navigate('/')} back={() => navigate('/')}/>
          </div>
        </main>
        :
        <div>
          <Calendar />
          <Footer />
          <ApplicationBar />
        </div>
      }
    </div>
  )
}

export default MyCalendar