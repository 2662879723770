import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import SecondHeader from '../components/headers/SecondHeader'
import EventsList from '../components/events/EventsList';
import { useContextState } from '../context/Context';
import { useGlobalModalContext } from '../components/modals/GlobalModal';
import LoginModal from '../components/modals/LoginModal';
import Footer from '../components/footers/Footer'
import ApplicationBar from '../components/shared/ApplicationBar';
import { useEventApi } from "../store";
import backgroundImg from '../backgroundImg.jpeg'


const MyTickets = () => {
    const { accountCTX, eventsCTX } = useContextState();
  const { signedIn } = accountCTX
  const {
    setEventPageType,
    allEventsState: { myticketsUpcoming, myticketsCompleted, myticketsCancelled, myticketsRequests, myticketsApproved },
    eventsState: { statusQuery },
  } = eventsCTX;

  const { getMyTickets } = useEventApi();
  const navigate = useNavigate();

  document.title = `FestiShare: My Tickets!`

  const [isInitialLoadPage, setIsInitialLoadPage] = useState(true);
  const [isInitialLoadCompleted, setIsInitialLoadCompleted] = useState(true);
  const [isInitialLoadCancelled, setIsInitialLoadCancelled] = useState(true);
  const [isInitialLoadUpcoming, setIsInitialLoadUpcoming] = useState(true);
  const [isInitialLoadRequests, setIsInitialLoadRequests] = useState(true);
  const [isInitialLoadApproved, setIsInitialLoadApproved] = useState(true);

  const [currentPageView, setCurrentPageView] = useState<string>('loader');

  const [resultIndexCompleted, setResultIndexCompleted] = useState<number>(1);
  const [resultIndexCancelled, setResultIndexCancelled] = useState<number>(1);
  const [resultIndexUpcoming, setResultIndexUpcoming] = useState<number>(1);
  const [resultIndexRequests, setResultIndexRequests] = useState<number>(1);
  const [resultIndexApproved, setResultIndexApproved] = useState<number>(1);

  const [hasMoreCompleted, setHasMoreCompleted] = useState<boolean>(true);
  const [hasMoreCancelled, setHasMoreCancelled] = useState<boolean>(true); 
  const [hasMoreUpcoming, setHasMoreUpcoming] = useState<boolean>(true); 
  const [hasMoreRequests, setHasMoreRequests] = useState<boolean>(true); 
  const [hasMoreApproved, setHasMoreApproved] = useState<boolean>(true); 

  const transformStatus = (statusQuery:string) => {
    return !isInitialLoadPage? (statusQuery as keyof typeof stateInfo): 'upcoming'
  }

  const stateInfo = {
    'completed': [myticketsCompleted, isInitialLoadCompleted, setIsInitialLoadCompleted, resultIndexCompleted, setResultIndexCompleted, hasMoreCompleted, setHasMoreCompleted],
    'cancelled': [myticketsCancelled, isInitialLoadCancelled, setIsInitialLoadCancelled, resultIndexCancelled, setResultIndexCancelled, hasMoreCancelled, setHasMoreCancelled],
    'upcoming': [myticketsUpcoming, isInitialLoadUpcoming, setIsInitialLoadUpcoming, resultIndexUpcoming, setResultIndexUpcoming, hasMoreUpcoming, setHasMoreUpcoming],
    'request': [myticketsRequests, isInitialLoadRequests, setIsInitialLoadRequests, resultIndexRequests, setResultIndexRequests, hasMoreRequests, setHasMoreRequests],
    'requestApproved': [myticketsApproved, isInitialLoadApproved, setIsInitialLoadApproved, resultIndexApproved, setResultIndexApproved, hasMoreApproved, setHasMoreApproved]
  }


  var stateInfoValue = stateInfo[transformStatus(statusQuery) as keyof typeof stateInfo ]
  var isInitialLoad = stateInfoValue[1]
  var resultIndex = stateInfoValue[3]
  var hasMore = stateInfoValue[5]

  const fetchEvents = async () => {
    if (currentPageView!=='loader'){setCurrentPageView('default')}
    const setResultIndex = stateInfoValue[4]
    const setHasMore = stateInfoValue[6]
    const setIsInitialLoad = stateInfoValue[2]

    const apiCall = await getMyTickets(statusQuery, resultIndex)
    if (apiCall && (apiCall.results.length > 0)) {
      setResultIndex(apiCall.next ? resultIndex + 1 : resultIndex);
      setHasMore(apiCall.next? true: false);
      if (isInitialLoad){setIsInitialLoad(false);}
    }
    else {setHasMore(false)}
    if (currentPageView==='loader'){setCurrentPageView('default')}
  };
  
  const loadMore = async () => {
    try {await fetchEvents();} 
    catch (error) { console.error('Error loading more events:', error);}
  };

  useEffect(() => {
    stateInfoValue = stateInfo[transformStatus(statusQuery) as keyof typeof stateInfo ]
    isInitialLoad = stateInfoValue[1]
    hasMore = stateInfoValue[5]
    resultIndex = stateInfoValue[3]
    if (isInitialLoad){fetchEvents(); setEventPageType('myticket');}
  }, [ statusQuery ])

  useEffect(()=>{
    setIsInitialLoadPage(false);
    setEventPageType('myticket');
  }, [])

  // console.log('lol1: ', statusQuery, stateInfo[statusQuery as keyof typeof stateInfo ])
  return (
      <div>
          {!signedIn? 
            <main className="flex isolate min-h-full">
              <img
                src={backgroundImg}
                alt=""
                className="absolute inset-0 -z-10 h-full w-full object-cover object-top"
              />
              <div className='mt-50'>
                <LoginModal close={true} closeButton={() => navigate('/')} back={() => navigate('/')}/>
              </div>
            </main>
            :
            <div>
                <SecondHeader headerType='mytickets' />
                <EventsList 
                    events={stateInfo[transformStatus(statusQuery) as keyof typeof stateInfo ][0]}
                    loadMore={loadMore}
                    hasMore={hasMore}
                />
            </div>
          } 
          <Footer />
          <ApplicationBar />
      </div>
  )
}

export default MyTickets