const protocol = window.location.protocol
var hostname = window.location.hostname
if(hostname.indexOf('www.') === 0){
    hostname = hostname.replace('www.','');
}
const port = window.location.port

const ws_schema = protocol === "http:" ? "ws:" : "wss:";

var baseUrl = `${protocol}//${hostname}`
var baseWs = `${ws_schema}//${hostname}`
if (port !== ''){
    baseUrl = `${baseUrl}:${port}`;
    // baseWs = `${baseWs}:8000/ws`
    baseWs = `${ws_schema}//127.0.0.1:8000/ws`
}


var backendUrl= '';
var backendSubDomain=''
var paypalId='AUxvYlrY8lCwpAAVaqpX6k91-qU4QqdGtErCSxGn9q3_jFH-4awwMLProHO4xafZtrDnZ7ejUWBSuyB3'
if (hostname === 'festishare.com'){
    paypalId = 'AW0utrqyPUaPFo6p76snExAXEFsSB667R9eEfxDOr1bbLa_7Jw6jFFy_LwOh9tGW1P8t-KVkcfVjPDzD'
}

if (hostname === 'sandbox.festishare.com' || hostname === 'festishare.com'){
    backendUrl = `${protocol}//${hostname}/test`
    backendSubDomain = `${hostname}/test`
    baseWs = `wss://${hostname}/ws`
}
else {
    backendUrl = `${protocol}//${hostname}:8000/api/v1/`
    backendSubDomain = `${hostname}`
}


export default {
    baseUrl: baseUrl,
    baseWs: baseWs,
    backendUrl: backendUrl,
    frontendSubDomain: hostname,
    backendSubDomain: backendSubDomain,
    paypalId: paypalId,
};

