import React, { useState } from 'react'
import { Label, TextInput, Textarea } from 'flowbite-react';
import InfoModal from '../modals/InfoModal';
import { BsInfoCircle } from 'react-icons/bs';
// import { useContextState } from '../../context/Context';


interface Props{
    eventPackage? : any;
    callBack?: () => void;
}

const EventTicketPrice: React.FC<Props> = ({eventPackage}) => {
    // const { eventsCTX } = useContextState();
    // const { pendingEvent } = eventsCTX
    // console.log('price: ', eventPackage.name)

    const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
    const [modalView, setModalView] = useState<'info' | 'success' | 'error'>('info');
    const [modalMessage, setModalMessage ] = useState<string>('');

    const infoForPricePackageName = "Here you will name the ticket option. An example could be: Early bird, General admission, VIP etc. You will be able to go back and add more ticket options after submitting the event."
    const infoForPricePackageDescription = "Add a description for this package. For example:  Entry only, complementary drink, free before 11:00 pm etc. "
    const infoForPricePackageMenPrice = "Price per man for this deal. Example: 10 (Put 0 if free entrance)"
    const infoForPricePackageLadiesPrice = "Price per lady for this deal. Example: 10 (Put 0 if free entrance)"
    const infoForPricePackageLadiesTickets = "Number of tickets available for this price package. Example: 20"
    
    return (
        <div>
            <div className="border-b border-gray-900/10 pb-6">
                <h2 className="text-base font-semibold leading-7 text-gray-900">Event Tickets</h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">Kindly add a ticket package for this event. You can add more packages after creating the event.</p>

                <div className="flex justify-between mb-2 block mt-6">
                    <Label htmlFor="packageName" value="Kindly provide the name of the ticket option" />
                    <a onClick={()=>{ setModalMessage(infoForPricePackageName); return setShowInfoModal(true)}} href='#'>
                        <div className='flex text-blue-600'> Info <BsInfoCircle /></div>
                    </a>
                </div>
                <TextInput 
                    type={ "text" } 
                    id="packageName" 
                    defaultValue={eventPackage? eventPackage.name:''}
                    required 
                />

                <div className="flex justify-between mb-2 block mt-6">
                    <Label htmlFor="packageDescription" value="Kindly provide a description for this ticket option" />
                    <a onClick={()=>{ setModalMessage(infoForPricePackageDescription); return setShowInfoModal(true)}} href='#'>
                        <div className='flex text-blue-600'> Info <BsInfoCircle /></div>
                    </a>
                </div>
                <Textarea
                    name="packageDescription"
                    id="packageDescription"
                    placeholder="Enter a description for your event ..."
                    rows={4}
                    defaultValue={eventPackage? eventPackage.description:''}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />

                <div className="flex justify-between mb-2 block mt-6">
                    <Label htmlFor="packagePriceMen" value="Ticket cost for men" />
                    <a onClick={()=>{ setModalMessage(infoForPricePackageMenPrice); return setShowInfoModal(true)}} href='#'>
                        <div className='flex text-blue-600'> Info <BsInfoCircle /></div>
                    </a>
                </div>
                <TextInput 
                    type={"number"} 
                    id="packagePriceMen" 
                    min={0}
                    defaultValue={eventPackage? eventPackage.priceMen:''}
                    required 
                />

                <div className="flex justify-between mb-2 block mt-6">
                    <Label htmlFor="packagePriceLadies" value="Ticket cost for ladies" />
                    <a onClick={()=>{ setModalMessage(infoForPricePackageLadiesPrice); return setShowInfoModal(true)}} href='#'>
                        <div className='flex text-blue-600'> Info <BsInfoCircle /></div>
                    </a>
                </div>
                <TextInput 
                    type={"number"} 
                    min={0}
                    id="packagePriceLadies" 
                    defaultValue={eventPackage? eventPackage.priceLadies:''}
                    required 
                />

                <div className="flex justify-between mb-2 block mt-6">
                    <Label htmlFor="packageAvailableTickets" value="Kindly provide the number of available tickets for this option" />
                    <a onClick={()=>{ setModalMessage(infoForPricePackageLadiesTickets); return setShowInfoModal(true)}} href='#'>
                        <div className='flex text-blue-600'> Info <BsInfoCircle /></div>
                    </a>
                </div>
                <TextInput 
                    type={"number"} 
                    id="packageAvailableTickets" 
                    min={1}
                    required 
                    defaultValue={eventPackage? eventPackage.availableTickets:''}
                />
            </div>

            { showInfoModal? 
                <InfoModal subject='Information' type={modalView} message={modalMessage} callBack={() => {return setShowInfoModal(false)}}/>: null
            }

        </div>
    )
}

export default EventTicketPrice