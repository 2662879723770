import axios, { AxiosError, AxiosHeaders } from "axios"
import { useContextState } from '../context/Context';
import Cookies from 'js-cookie';
import config from "../config";
import { useState } from "react";

// import { getCookie, setCookie } from 'typescript-cookie'

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.withCredentials = true;



export const useApi = () => {
    const { accountCTX } = useContextState();
    const { setSignedIn } = accountCTX

    // const [isRefreshingToken, setIsRefreshingToken] = useState<boolean>(false)

    const api = axios.create({
        baseURL: config.backendUrl,
    });

    const refreshApi = axios.create({
        baseURL: config.backendUrl,
    });

    // var temporaryRefreshToken = refreshToken
    const delay = (milliseconds:number) => new Promise(resolve => setTimeout(resolve, milliseconds));

    // const refreshTokenCall = async()=>{
    //     if ((!Cookies.get('token')) || (Cookies.get('token') === '')){
    //         try {
    //             var credentials={'refresh': temporaryRefreshToken}
    //             // console.log(credentials)
    //             const response = await refreshApi.post('token/refresh/', credentials )
    //             // console.log('res: ', response)
    //             if (response && response.status === 200){
    //                 const { access, refresh } = response.data;
    //                 temporaryRefreshToken = refresh
    //                 setToken(access)
    //                 setRefreshToken(refresh)
    
    //                 // console.log('access: ', access)
    //                 return access
    //             }
    //             return
    //         }
    //         catch (err: any) {
    //             console.log(err)
    //             return 
    //         }
    //     }
    //     return Cookies.get('token')
    // }

    // api.interceptors.request.use(request => {
    //     // console.log('Starting Request', JSON.stringify(request, null, 2))
    //     return request
    // })

    
    // Add a response interceptor (Refresh Token)
    // https://blog.theashishmaurya.me/handling-jwt-access-and-refresh-token-using-axios-in-react-app
    // api.interceptors.response.use(
    //     (response) => {
    //         return response
    //     },
    //     async (error) => {
    //         const originalRequest = error.config;
    //         console.log('originalRequest: ', originalRequest)

    //         // If the error status is 401 and there is no originalRequest._retry flag,
    //         // it means the token has expired and we need to refresh it
    //         if (error.response?.status === 401 && !originalRequest._retry) {
                
    //                 originalRequest._retry = true;
        
    //                 try {
    //                     // var credentials={'refresh': refreshToken}
    //                     var credentials={'refresh': Cookies.get('refreshToken')}
    //                     console.log('starting token refresh')
    //                     const response = await refresh.post('token/refresh/', credentials )
    //                     .then((result:any) => {
    //                         console.log('refresh result: ', result)
    //                         if (result && result.status === 200){
    //                             const { access, refresh } = result.data;

    //                             // const tokens = JSON.stringify(result.data)
    //                             // console.log('token : ', token)
    //                             console.log('new token : ', access)

    //                             // console.log('refreshToken: ', refreshToken)

    //                             // // console.log(access, refresh)
    //                             // console.log('refreshed token :', refresh)

    //                             setToken(access)
    //                             setRefreshToken(refresh)

    //                             // Retry the original request with the new token
    //                             var newRequest = originalRequest
    //                             // newRequest.headers.Authorization = `Bearer ${access}`;
    //                             error.config.headers['Authorization'] = `Bearer ${access}`;
    //                             // newRequest.headers.Authorization = `Bearer ${access}`;
    //                             // originalRequest.headers.setAuthorization('Bearer ' + access);
    //                             console.log('New Request: ', newRequest)
    //                             console.log('resent original request with new token')
    //                             return api(error.config);
    //                             // return api(newRequest);
    //                         }
    //                         else{
    //                             console.log('refresh failed')
    //                             // setSignedIn(false)
    //                             // console.log(error)
    //                         }
    //                     });
    //                 } catch (error) {
    //                     // Handle refresh token error or redirect to login
    //                     localStorage.removeItem('refresh');
    //                     console.log('refresh error')
    //                     // setSignedIn(false)
    //                     console.log(error)
    //                 }
                

    //             return 
    //         }
    //         else if(error.response.status === 401){
    //             // setSignedIn(false)
    //             console.log(error)
    //             console.log('error')
    //         }
    //         return Promise.reject(error);
    //     }
    // );

    api.interceptors.response.use(
        (response) => response,  // Pass through successful responses
        async (error) => {
            const originalRequest = error.config;
            // console.log('starting token refresh')
            // console.log('original: ', originalRequest)
      
            if (error.response?.status === 401 && !originalRequest._retry) {
                setSignedIn(false)
                // originalRequest._retry = true;
        
                // try {
                //     // const credentials = { refresh: refreshToken };
                //     const response = await refreshTokenCall();
                //     // console.log('lol: ', response)
                //     if (response) {
                //         // Retry directly with updated headers
                //         error.config.headers.Authorization = `Bearer ${response}`;
                //         api.defaults.headers['Authorization'] = `Bearer ${response}`;
                //         return refreshApi(error.config);
                //     } else {
                //         console.error('Refresh failed');
                //         // Handle refresh failure (e.g., redirect to login)
                //         setSignedIn(false)
                //     }
                // } catch (error) {
                //     console.error('Refresh error');
                //     // console.error(error);
                //     setSignedIn(false)
                //     // Handle refresh errors (e.g., remove refresh token, redirect to login)
                // }
            } 
            // else if (error.response?.status === 401) {
            //     // Handle other 401 errors (e.g., invalid token)
            //     setSignedIn(false)
            //     // console.log(error)
            //     console.log('error')
            // }
        
            return Promise.reject(error);
        }
    );


    // api.interceptors.request.use(async (config) => {
    //     const token = Cookies.get('token');
    
    //     if (token !== null) {
    //         config.headers.Authorization = 'Bearer ' + token;
    //         // @ts-ignore
    //         console.debug('[Request]', config.baseURL + config.url, JSON.stringify(token));
    //     }
    //     return config;
    // });

    
    
    // const getHeaders = async(authentication: boolean, secured?: boolean) => {
    //     // console.log(token)
    //     const headers: AxiosHeaders = new AxiosHeaders()
    //     if (authentication) {
    //         if (Cookies.get('token')){
    //             console.log('token found')
    //             headers.setAuthorization('Bearer ' + Cookies.get('token'))
    //         }
    //         else {
    //             console.log('token not found')
    //             const newAccessToken = await refreshTokenCall()
    //             if (typeof newAccessToken !== 'undefined'){
    //                 console.log('result: ', newAccessToken)
    //                 headers.setAuthorization('Bearer ' + newAccessToken)
    //             }
    //         }
    //     }
    //     headers.setContentType('multipart/form-data')
    //     console.log(headers.toJSON());
    //     return headers
    // }

    const getHeaders = async(authentication: boolean, secured?: boolean) => {
        // console.log(token)
        const headers: AxiosHeaders = new AxiosHeaders()
        // if (authentication) {
        //     headers.setAuthorization('Bearer ' + Cookies.get('token'))
        // }
        headers.setContentType('multipart/form-data')
        // console.log(headers.toJSON());
        return headers
    }

    const makeFormData = (obj: any) => {
        const formData = new FormData()
        Object.keys(obj).forEach(key => formData.append(key, obj[key]))
        return formData
    }

    const setUserId = (id: string) => {
        sessionStorage.setItem('userId', id)
    }

    const $get = async (path: string, authentication:boolean, body= {}, buildForm=true, ) => {
        
        try {
            const { data } = await api.get(path, { headers: await getHeaders(authentication), params:body, withCredentials: true })
            return data
        } catch (err: any) {
            console.log(err.message)
        }
    }

    const $post = async (path: string, body: any, authentication = true, buildForm=true, secured =false) => {

        var formData = body
        if (buildForm){formData = makeFormData(body)}
         
        // console.log(Object.fromEntries(formData))
        try {
            const { data } = await api.post(path, formData, 
                { headers: await getHeaders(authentication, secured), withCredentials: true })

            return data
        } catch (error: any) {
            // console.log(error);
            // console.log('res: ', (error.response?.status))
            // console.log('err: ', error.config.url)
            if (error.config.url === 'signin/' && error?.response?.data['non_field_errors'][0] === 'Incorrect Credentials'){
                return {'res': 'failed', 'message': 'Incorrect Credentials'}
            }

            if ((error.response?.status === 401) && (error.config.url === 'signout/')){
                console.log('Signout failed ...')
            }
            // console.log(err?.response);
            // console.log(err?.response?.data['non_field_errors'][0]);
            // window.alert(err?.response?.data['non_field_errors'][0])
        }
    }

    const $put = async (path: string, body: any) => {
        try {
            const { data } = await api.put(path, body, { headers: await getHeaders(true) })
            return data
        } catch (err: any) {
            console.log(err?.response);
            console.log(err.message)
        }
    }

    const $patch = async (path: string, body: any) => {
        try {
            const { data } = await api.patch(path, body, { headers: await getHeaders(true) })
            return data
        } catch (err: any) {
            console.log(err.message)
        }
    }

    const $delete = async (path: string, body= {}, buildForm=true,) => {
        var formData = body
        if (buildForm){formData = makeFormData(body)}
        try {
            const { data } = await api.delete(path, { headers: await getHeaders(true), data:formData })
            return data
        } catch (err: any) {
            console.log(err.message)
        }
    }

    const objToArray = (data: any): any[] => {
        const formatedData: any[] = [];
        Object.keys(data).map((item, i) => {
            const operation = Object.keys(data[item]).map((key, i) => ({
                id: key,
                date: item,
                ...data[item][key],
            }));
            formatedData.push(...operation);
        });
        return formatedData
    }


    return { getHeaders, setUserId, $post, $get, $put, $patch, $delete, objToArray }
}