import React, { useState, useRef } from 'react'
import { CheckIcon } from '@heroicons/react/20/solid'
import { useContextState } from '../../context/Context'
import { useGlobalModalContext, MODAL_TYPES } from '../modals/GlobalModal'
import { useEventApi } from "../../store";
import { EventTicketPackage } from '../../models';
import { AiOutlineEdit, AiFillDelete } from 'react-icons/ai';
import Loader from '../shared/Loader';
import AlertComponent from '../shared/AlertComponent';
import { Button } from 'flowbite-react';

interface Props{
    event : any;
    eventHasEnded? : boolean;
    callBack?: () => void;
}

const EventPricing: React.FC<Props> = ({event, eventHasEnded}) => {
    const { eventsCTX } = useContextState();
    const { eventPageType } = eventsCTX

    const { deleteEventTicketPackage } = useEventApi();

    const { hideModal, store, showModal } = useGlobalModalContext();
    const { modalProps } = store || {};
    const [currentSubView, setCurrentSubView] = useState<string>('default');

    const [openModal, setOpenModal] = useState<string | undefined>();
    const props = { openModal, setOpenModal };

    

    const getEventPackageModal = (eventPackageValue:any) => {
        props.setOpenModal('initial-focus');
        showModal(MODAL_TYPES.GET_EVENT_PACKAGE_MODAL, { 
            title: "Create instance form", 
            confirmBtn: "Close", 
            eventPackage: eventPackageValue, 
            eventName: event.name,
            eventId: event.id,
            premium: event.premium
        });
    };

    const editEventPackageModal = (eventPackageValue:any) => {
        props.setOpenModal('initial-focus');
        showModal(MODAL_TYPES.EDIT_EVENT_PACKAGE_MODAL, { title: "Create instance form", confirmBtn: "Close", eventPackage: eventPackageValue, eventId: event.id });
    };

    const handleDelete = async (id:any) => {
        setCurrentSubView('loader')
        const data = {'id': id}
        var apiCall = await deleteEventTicketPackage(id, event.id)
        .then((result:any) => {
            if (result){setCurrentSubView('success')}else{setCurrentSubView('error')}
        });
        
    };

    var pricesList: EventTicketPackage[] = []
    if (event && event.price){
        pricesList = event.price
    }

    console.log(event.price)

    return (
        <div className="bg-white py-24 sm:py-32">
            {(currentSubView ==='loader')? <Loader />:null}

            {(currentSubView ==='default')? 
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-4xl text-center">
                        <h2 className="text-base font-semibold leading-7 text-indigo-600">Event Pricing</h2>
                        <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                            Pricing packages for this event
                        </p>
                    </div>
                    <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
                        Below are the different pricing packages for this event
                    </p>
                    { (eventPageType === 'myevent') && (!eventHasEnded)?
                        <div className="mt-3 flex items-center justify-center gap-x-6">
                            <a
                            href="#"
                            onClick={(e) =>{ editEventPackageModal(null); e.preventDefault();}}
                            className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >Add package</a>
                        </div>:null
                    }
                    <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                        
                        {pricesList.map((price, index) => (
                            <div key={index} className={'flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 xl:p-10'} >
                                <div className=''>
                                    <div className="flex items-center justify-between gap-x-4">
                                        <h3 id={price.id} className={'text-lg font-semibold leading-8'}>{price.name}</h3>
                                    </div>
                                    <p className="mt-4 text-sm leading-6 text-gray-600">{price.description}</p>
                                    <p className="mt-6 flex items-baseline gap-x-1">
                                        <span className="text-4xl font-bold tracking-tight text-gray-900">{price.priceMen}</span>
                                        <span className="text-sm font-semibold leading-6 text-gray-600">/for men</span>
                                    </p>
                                    <p className="mt-6 flex items-baseline gap-x-1">
                                        <span className="text-4xl font-bold tracking-tight text-gray-900">{price.priceLadies}</span>
                                        <span className="text-sm font-semibold leading-6 text-gray-600">/for ladies</span>
                                    </p>
                                    <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600">
                                        <li  className="flex gap-x-3">
                                            <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                            Total Tickets: {price.tickets} tickets
                                        </li>
                                        <li  className="flex gap-x-3">
                                            <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                            Remaining Tickets: {price.availableTickets} tickets
                                        </li>
                                        { eventPageType === 'myevent'?
                                            <li  className="flex gap-x-3">
                                                <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                                Sold: <b>$ {price.cash_balance} </b>
                                            </li>:null
                                        }
                                    </ul>
                                </div>

                                { eventPageType !== 'myevent'?
                                    <div>
                                        {(price.availableTickets !== 0)?
                                            <a
                                                onClick={(e) =>{ getEventPackageModal(price); e.preventDefault();}}
                                                href='#'
                                                className={'bg-indigo-600 text-white shadow-sm hover:bg-indigo-500 mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'}
                                            >
                                                Get Ticket
                                            </a> : 
                                            <div className="w-full flex justify-center mt-6">
                                                <Button type='button' color="failure" pill disabled={true} >Sold Out</Button>
                                            </div>
                                        }
                                        
                                    </div>:

                                    <div>
                                        <div className="border-t mt-3 flex divide-x divide-gray-200">
                                            { !eventHasEnded && (price.availableTickets === price.tickets)?
                                                <div className="flex w-0 flex-1">
                                                    <a
                                                        onClick={(e) =>{ editEventPackageModal(price); e.preventDefault();}}
                                                        href='#'
                                                        className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                                                    >
                                                        <AiOutlineEdit className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                        Edit
                                                    </a>
                                                </div>:null
                                            }
                                            { ((pricesList.length > 1) && (price.tickets === price.availableTickets))? 
                                                <div className="-ml-px flex w-0 flex-1">
                                                    <a
                                                        onClick={(e) =>{ handleDelete(price.id); e.preventDefault();}}
                                                        href='#'
                                                        className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                                                    >
                                                        <AiFillDelete className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                        Delete
                                                    </a>
                                                </div> : null
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        ))}
                        { pricesList?.length === 0 ? <p>No pricing packages yet.</p>:null}
                    
                    </div>
                </div>:null
            }

            {(currentSubView ==='success')? 
                <AlertComponent 
                    callBack={() => {setCurrentSubView('default')}} 
                    type='success' 
                    subject='Success' 
                    message='Congratulations, you have successfully deleted this package ...' 
                />: null
            } 

            {(currentSubView ==='error')? 
                <AlertComponent 
                    callBack={() => {setCurrentSubView('default')}} 
                    type='error' 
                    subject='Failed' 
                    message='Sorry, an error occured ...' 
                />: null
            } 

        </div>
    )
}


export default EventPricing