import { useEffect, useState, useReducer } from 'react';
import { eventsReducer, eventsFilterReducer } from './Reducers';

export const useEventsContext = () => {
    
    const currentEventIdStorageValue = window.localStorage.getItem("currentEventId");
    const pendingEventStorageValue = window.localStorage.getItem("pendingEvent");
    const eventStorageValue = window.localStorage.getItem("eventState");
    const eventsStorageValue = window.localStorage.getItem("events");
    const myEventsCompletedStorageValue = window.localStorage.getItem("myEventsCompleted");
    const myEventsUpcomingStorageValue = window.localStorage.getItem("myEventsUpcoming");
    const myEventsCancelledStorageValue = window.localStorage.getItem("myEventsCancelled");
    const myTicketsCompletedStorageValue = window.localStorage.getItem("myTicketsCompleted");
    const myTicketsCancelledStorageValue = window.localStorage.getItem("myTicketsCancelled");
    const myTicketsUpcomingStorageValue = window.localStorage.getItem("myTicketsUpcoming");
    const myTicketsRequestsStorageValue = window.localStorage.getItem("myTicketsRequests");
    const myTicketsApprovedStorageValue = window.localStorage.getItem("myTicketsApproved");
    const [allEventsState, dispatchAllEventsState] = useReducer(eventsReducer, { 
        currentEventId: (currentEventIdStorageValue && currentEventIdStorageValue !== 'undefined')? currentEventIdStorageValue : '',
        pendingEvent: (pendingEventStorageValue && pendingEventStorageValue !== 'undefined')? JSON.parse(pendingEventStorageValue) : '',
        currentEvent: (eventStorageValue && eventStorageValue !== 'undefined')? JSON.parse(eventStorageValue) : '',
        events: (eventsStorageValue && eventsStorageValue !== 'undefined')? JSON.parse(eventsStorageValue) : [],
        myEventsCompleted: (myEventsCompletedStorageValue && myEventsCompletedStorageValue !== 'undefined')? JSON.parse(myEventsCompletedStorageValue) : [],
        myEventsUpcoming: (myEventsUpcomingStorageValue && myEventsUpcomingStorageValue !== 'undefined')? JSON.parse(myEventsUpcomingStorageValue) : [],
        myEventsCancelled: (myEventsCancelledStorageValue && myEventsCancelledStorageValue !== 'undefined')? JSON.parse(myEventsCancelledStorageValue) : [],
        myticketsCompleted: (myTicketsCompletedStorageValue && myTicketsCompletedStorageValue !== 'undefined')? JSON.parse(myTicketsCompletedStorageValue) : [],
        myticketsCancelled: (myTicketsCancelledStorageValue && myTicketsCancelledStorageValue !== 'undefined')? JSON.parse(myTicketsCancelledStorageValue) : [],
        myticketsUpcoming: (myTicketsUpcomingStorageValue && myTicketsUpcomingStorageValue !== 'undefined')? JSON.parse(myTicketsUpcomingStorageValue) : [],
        myticketsRequests: (myTicketsRequestsStorageValue && myTicketsRequestsStorageValue !== 'undefined')? JSON.parse(myTicketsRequestsStorageValue) : [],
        myticketsApproved: (myTicketsApprovedStorageValue && myTicketsApprovedStorageValue !== 'undefined')? JSON.parse(myTicketsApprovedStorageValue) : [],
        loved: []
    }); 

    // ......................CURRENT EVENT ID
    useEffect(() => {
        window.localStorage.setItem("currentEventId", allEventsState.currentEventId);
    }, [allEventsState.currentEventId]);

    // ......................PENDING EVENT
    // useEffect(() => {
    //     window.localStorage.setItem("pendingEvent", JSON.stringify(allEventsState.pendingEvent));
    // }, [allEventsState.pendingEvent]);

    // ......................EVENTS
    // useEffect(() => {
    //     window.localStorage.setItem("events", JSON.stringify(allEventsState.events));
    // }, [allEventsState.events]);

    // ......................CURRENT EVENT PAGE
    useEffect(() => {
        window.localStorage.setItem("eventState_time", (new Date()).toString());
        // window.localStorage.setItem("eventState", JSON.stringify(allEventsState.currentEvent));
    }, [allEventsState.currentEvent]);


    // ......................MY EVENTS PAGE
    // useEffect(() => {
    //     window.localStorage.setItem("myEvents", JSON.stringify(allEventsState.myevents));
    // }, [allEventsState.myevents]);


    // ......................MY TICKETS PAGE
    // useEffect(() => {
    //     window.localStorage.setItem("myTickets", JSON.stringify(allEventsState.mytickets));
    // }, [allEventsState.mytickets]);


    

    
    // ......................SINGLE EVENT PAGE
    const [eventPageType, setEventPageType] = useState<string>(
        () => {
            const localStorageValue = window.localStorage.getItem("eventPageType");
            return (localStorageValue && localStorageValue !== 'undefined')? JSON.parse(localStorageValue) : '';
        }
    );
    
    useEffect(() => {
        // console.log(eventPageType)
        window.localStorage.setItem("eventPageType", JSON.stringify(eventPageType));
    }, [eventPageType]);

    

    // ......................FILTER
    const [eventsState, eventsDispatch] = useReducer(eventsFilterReducer, {
        sort: 'soonToLater',
        byStock: false,
        byFastDelivery: false,
        byRating: 0,
        searchQuery: "",
        searchAndStatusQuery: {'query':'', 'status':''},
        statusQuery: "upcoming",
        ticketStatusQuery: "active",
        ticketAndEventStatusQuery: {"event":"upcoming", "ticket":"active", 'operator': 'AND'},
    });


    return { allEventsState, dispatchAllEventsState, eventPageType, setEventPageType, eventsState, eventsDispatch }

}