
import Footer from '../components/footers/Footer'
import ApplicationBar from '../components/shared/ApplicationBar';
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { useEventApi } from '../store';
import Tickets from '../components/events/Tickets';


const TicketsPage = () => {
  const { getTickets } = useEventApi();

  const { ticketId } = useParams()

  const [currentSubView, setCurrentSubView] = useState<string>('default');
  const [message, setMessage] = useState<string>('')
  const [eventId, setEventId] = useState<string>('')
  const [eventName, setEventName] = useState<string>('')
  const [tickets, setTickets] = useState<any[]>()

  const getTicketsInfo = async (ticketId:string) => {
    const apiCall = await getTickets(ticketId).then((result:any) => {
      // console.log('lol: ', result)
      if (result && result.res === 'success'){
          // console.log(result)
          setTickets(result.data.tickets)
          setEventId(result.data.eventId)
          setEventName(result.data.eventName)
          document.title = `Festishare - Your ticket(s) to: ${result.data.eventName}`
          // setEventToShow(result.data[0])
          // setCurrentSubView('default')
      }
      else if (result && result.res === 'failed'){
        setMessage(result.message)
        setCurrentSubView('error')
      }
      else{
        setMessage('We could not find this ticket')
        setCurrentSubView('error')
      }
    });
  }

  useEffect(() => {
    // console.log('ticketId: ', ticketId)
    if (typeof ticketId !== 'undefined'){getTicketsInfo(ticketId)}
    
  }, [ticketId])

  document.title = `FestiShare: Tickets!`

  return (
    <div >
      { tickets && (currentSubView === 'default')? 
        <div>
          <Tickets eventId={eventId} eventName={eventName} tickets={tickets} />
        </div>:null
      }
      { (currentSubView === 'error')?
        <div className="bg-red-200">
          <p>{message}</p>
        </div>:null
      }
      <Footer />
      <ApplicationBar />
    </div>
  )
}

export default TicketsPage