import { useState, useEffect } from 'react'
import EventCard from "./EventCard"
import { useContextState } from '../../context/Context'
import { useEventApi } from "../../store";
import { useShared } from '../../store';
// import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Loader from '../shared/Loader'
import { Event } from '../../models';
import { Button } from 'flowbite-react';
import { FaRegMap, FaList } from "react-icons/fa";
import MapFromGoogle from '../map/MapFromGoogle';
import InfiniteScroll from 'react-infinite-scroll-component';


interface Props{
  events: Event[] | any[];
  loadMore: ()=> void;
  hasMore: boolean;
  purpose?: string;
  eventType?: string;
  eventCallBack? : (event:Event) => void;
}
  
const EventsList: React.FC<Props> = ({events, loadMore, hasMore, purpose, eventType, eventCallBack}) => {
  const [currentView, setCurrentView] = useState<string>('list');

  const { eventsCTX } = useContextState();
  const { dispatchAllEventsState, } = eventsCTX;

  const seeEvent = (e:React.MouseEvent<HTMLAnchorElement, MouseEvent>, event:any) => {
    dispatchAllEventsState({ type: 'SET_CURRENT_EVENT_ID', payload: event.id });
  };

  return (
    <div className="bg-white">
      {(currentView === 'list') && events?
        <div>
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <InfiniteScroll
              dataLength={events.length}
              next={loadMore}
              hasMore={hasMore}
              loader={<Loader />}
              endMessage={<p className='mt-6 mb-6'>No events to show...</p>}
            >
              <ul
                role="list"
                className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3"
              >
                {
                    events.map((event:any, index:any) => {
                      if ( eventType && event.type === eventType){
                        return <Link key={index} to={"/event"} onClick={ (e) =>{ 
                          // return <Link key={index} to={"/event/" + event.id} onClick={ (e) =>{ 
                            if (eventCallBack && purpose === 'similar events') {eventCallBack(event)}; 
                            return seeEvent(e, event) 
                          }}>
                            <EventCard event={event} />
                          </Link>     
                      }
                      else if (!eventType || eventType === '') {
                        return <Link key={index} to={"/event"} onClick={ (e) => seeEvent(e, event) }><EventCard event={event} /></Link>
                          // return <Link key={index} to={"/event/" + event.id} onClick={ (e) => seeEvent(e, event) }><EventCard event={event} /></Link>
                      }
                    }
                  )
                }
              </ul>
            </InfiniteScroll>
          </div>
          <div className='fixed inset-x-0 flex items-center justify-center bottom-[90px] z-20'>
            <Button color="blue" pill onClick={()=>{return setCurrentView('map')}}  >
              <FaRegMap size={'27px'} />
              Show Map
            </Button>
          </div>
        </div>:null

      }

      {(currentView === 'map') && events?
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <MapFromGoogle events={events} />

          <div className='fixed inset-x-0 flex items-center justify-center bottom-[90px] z-20'>
            <Button color="blue" pill onClick={()=>{return setCurrentView('list')}}  >
              <FaList size={'27px'} />
              List View
            </Button>
          </div>

        </div>:null
      }

      {!events? <div className='mt-6'><Loader /></div>:null}
      
      {/* <div className="bg-white py-24 sm:py-32"> */}
      
    </div>
  )
}


export default EventsList
  