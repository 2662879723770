import React, { useEffect, useState } from 'react'
import { useContextState } from '../context/Context';
import { useNavigate } from "react-router-dom";
import InboxList from '../components/messages/InboxList'
import LoginModal from '../components/modals/LoginModal';
import { useNotificationApi } from '../store';
import SecondHeader from '../components/headers/SecondHeader';
import { Button } from 'flowbite-react';
import backgroundImg from '../backgroundImg.jpeg'

interface Props{
  type: string,
}

const Inbox: React.FC<Props> = ({ type }) => {
  const { accountCTX, inboxCTX, eventsCTX } = useContextState();
  const { signedIn } = accountCTX
  const { setEventPageType } = eventsCTX;

  const { dispatchInboxState } = inboxCTX;

  const { getPrivateInboxUnreadMessagesCount, getEventsInboxUnreadMessagesCount } = useNotificationApi()

  const navigate = useNavigate();

  const [modalType, setModalType] = useState<string>('');
  const [showNewModal, setShowNewModal] = useState<boolean>(false);

  useEffect(()=>{
    setEventPageType('');
    getPrivateInboxUnreadMessagesCount();
    getEventsInboxUnreadMessagesCount();
  }, [])

  useEffect(()=>{
    if (type === 'private'){
      dispatchInboxState({ type: 'SET_INBOX_STATE', payload: 'private' })
    }

    else if (type === 'support'){
      dispatchInboxState({ type: 'SET_INBOX_STATE', payload: 'support' })
    }
    
  }, [type])

  document.title = `FestiShare: My Inbox`

  return (
    <div>
      {!signedIn? 
        <main className="flex isolate min-h-full">
          <img
            src={backgroundImg}
            alt=""
            className="absolute inset-0 -z-10 h-full w-full object-cover object-top"
          />
          <div className='mt-50'>
            <LoginModal close={true} closeButton={() => navigate('/')} back={() => navigate('/')}/>
          </div>
        </main>
        :
        <div>
          {(type === 'support')?
            <div >
                <h3 className="text-base flex justify-center font-semibold leading-7 text-gray-900">Support Messages</h3>
                <Button className='mt-24' type='button' gradientMonochrome="success" pill onClick={()=>{setModalType('Support Message'); return setShowNewModal(true)}}>
                  {/* <BsQrCodeScan className="h-5 w-5 mr-3" />  */}
                  Send a Support Message
                </Button>
            </div>
            :
            <SecondHeader headerType='messages' />
          }

          <InboxList 
            type={type} 
            modalType={modalType}
            setModalType={setModalType}
            showNewModal={showNewModal}
            setShowNewModal={setShowNewModal}
          />

        </div>
      }
    </div>
  )
}

export default Inbox