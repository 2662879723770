import { useApi } from "./api"
import { useContextState } from '../context/Context';


export const useNotificationApi = () => {
    const { $post, $get } = useApi()

    const { accountCTX, notificationCTX } = useContextState();
    const { signedIn } = accountCTX
    const { 
        setPrivateAndEventsInboxUnreadMessagesCount,
        setEventsInboxUnreadMessagesCount,
        setPrivateInboxUnreadMessagesCount,
        setFriendRequestsCount,
    } = notificationCTX


    const saveDeviceToken = async (credentials: {device_id:string}) => {
        if (!signedIn){return}
        var data = await $post('register_device_token/', credentials, true)
        // console.log(data.data)
        if (data && (data.res === 'success' || data.res === 'failed')){
            return data
        }
        return 
    }

    const getPrivateInboxUnreadMessagesCount = async () => {
        if (!signedIn){return}
        var data = await $get('messages/inbox/get_private_inbox_count/', true)
        // console.log(data)
        if (data && (data.res === 'success')){
            setPrivateInboxUnreadMessagesCount(data.count)
            // 
            return data
        }
        return 
    }

    const getEventsInboxUnreadMessagesCount = async () => {
        if (!signedIn){return}
        var data = await $get('messages/inbox/get_events_inbox_count/', true)
        // console.log(data)
        if (data && (data.res === 'success')){
            setEventsInboxUnreadMessagesCount(data.count)
            return data
        }
        return 
    }

    const getPrivateAndEventsInboxUnreadMessagesCount = async () => {
        if (!signedIn){return}
        var data = await $get('messages/inbox/get_private_and_events_inbox_count/', true)
        // console.log(data)
        if (data && (data.res === 'success')){
            setPrivateAndEventsInboxUnreadMessagesCount(data.count)
            return data
        }
        return 
    }

    const getFriendRequestsCount = async () => {
        if (!signedIn){return}
        var data = await $get('account/friendship/get_requests_count/', true)
        // console.log(data)
        if (data && (data.res === 'success')){
            setFriendRequestsCount(data.count)
            return data
        }
        return 
    }



    return {
        saveDeviceToken,
        getPrivateInboxUnreadMessagesCount,
        getEventsInboxUnreadMessagesCount,
        getPrivateAndEventsInboxUnreadMessagesCount,
        getFriendRequestsCount
    }

}