import React, {useState} from 'react'
import { useContextState } from "../../context/Context";
import { useGlobalModalContext, MODAL_TYPES } from '../modals/GlobalModal'
import { Link } from 'react-router-dom';
import { MdOutlineDirectionsBoatFilled, MdBusiness, MdPool } from 'react-icons/md';
import { BsBusFront, BsFillHouseHeartFill, BsBuildings } from 'react-icons/bs';
import { FaHotel } from 'react-icons/fa';
import { GiPartyPopper } from 'react-icons/gi';
import { IoIosPeople } from 'react-icons/io';



const ApplicationBar = () => {
    const { showModal } = useGlobalModalContext();
    const [openModal, setOpenModal] = useState<string | undefined>();

    const props = { openModal, setOpenModal };

    const { eventsCTX, notificationCTX } = useContextState();
    const {
        allEventsState: { cart },
        dispatchAllEventsState,
        eventsDispatch,
    } = eventsCTX;

    const { 
        friendRequestsCount,
        privateAndEventsInboxUnreadMessagesCount
    } = notificationCTX;


    const handleHome = () => {
        eventsDispatch({
            type: "FILTER_BY_SEARCH",
            payload: ""
        });
    };

    const Create_Event_Modal = () => {
        props.setOpenModal('initial-focus');
        showModal(MODAL_TYPES.CREATE_EVENT_MODAL, {
          title: "CREATE_EVENT_MODAL",
          confirmBtn: "Save"
        });
    };


  return (
    <div>
        
        <div className="fixed z-50 w-full h-16 max-w-lg -translate-x-1/2 bg-white border border-gray-200 rounded-full bottom-4 left-1/2 dark:bg-gray-700 dark:border-gray-600">
            <div className="grid h-full max-w-lg grid-cols-5 mx-auto">

                <Link to="/" onClick={() => handleHome()}  data-tooltip-target="tooltip-home" type="button" className="inline-flex flex-col items-center justify-center px-5 rounded-l-full hover:bg-gray-50 dark:hover:bg-gray-800 group">
                    <svg className="w-5 h-5 mb-1 text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
                    </svg>
                    <h6 className="text-xs font-medium text-gray-700 group-hover:text-gray-900 ">Home</h6>
                </Link>

                <Link to="/mytickets" data-tooltip-target="tooltip-wallet" type="button" className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-ticket-detailed w-5 h-5 mb-1 text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500" viewBox="0 0 20 20">
                        <path d="M4 5.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5Zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5ZM5 7a1 1 0 0 0 0 2h6a1 1 0 1 0 0-2H5Z"/>
                        <path d="M0 4.5A1.5 1.5 0 0 1 1.5 3h13A1.5 1.5 0 0 1 16 4.5V6a.5.5 0 0 1-.5.5 1.5 1.5 0 0 0 0 3 .5.5 0 0 1 .5.5v1.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 11.5V10a.5.5 0 0 1 .5-.5 1.5 1.5 0 1 0 0-3A.5.5 0 0 1 0 6V4.5ZM1.5 4a.5.5 0 0 0-.5.5v1.05a2.5 2.5 0 0 1 0 4.9v1.05a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-1.05a2.5 2.5 0 0 1 0-4.9V4.5a.5.5 0 0 0-.5-.5h-13Z"/>
                    </svg>
                    <h6 className="text-xs font-medium text-gray-700 group-hover:text-gray-900">My&nbsp;Tickets</h6>
                </Link>

                <div className="flex items-center justify-center">
                    <button onClick={Create_Event_Modal} data-tooltip-target="tooltip-new" type="button" className="inline-flex items-center justify-center w-10 h-10 font-medium bg-blue-600 rounded-full hover:bg-blue-700 group focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800">
                        <svg className="w-4 h-4 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16"/>
                        </svg> 
                        <span className="sr-only">New item</span>
                    </button>
                </div>

                <Link to="/myevents" data-tooltip-target="tooltip-settings" type="button" className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">
                    <GiPartyPopper className="w-5 h-5 mb-1 text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500" />
                    <h6 className="text-xs font-medium text-gray-700 group-hover:text-gray-900 ">My&nbsp;Events</h6>
                </Link>
                
                <Link to="/social" data-tooltip-target="tooltip-profile" type="button" className=" inline-flex flex-col items-center justify-center px-5 rounded-r-full hover:bg-gray-50 dark:hover:bg-gray-800 group">
                    <div className='relative'>
                        <svg className="w-5 h-5 mb-1 text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
                        </svg>
                        { (friendRequestsCount || privateAndEventsInboxUnreadMessagesCount)?
                            <div className="absolute inline-flex items-center justify-center w-4 h-4 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900">
                                
                            </div>:null
                        }
                    </div>
                    <h6 className="text-xs font-medium text-gray-700 group-hover:text-gray-900 ">Social</h6>
                    
                </Link>
            </div>
        </div>

        
    </div>
  )
}

export default ApplicationBar