import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useContextState } from '../../context/Context';
import { useMessagesWs, useMessagesApi, useShared } from "../../store";
import Loader from '../shared/Loader';
import AlertComponent from '../shared/AlertComponent';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { BsChevronDoubleDown } from "react-icons/bs";
import '../styles.css'
import SingleMessage from './SingleMessage';
import ChatInput from './chatInput';
import ImagesUpload from '../images/ImagesUpload';
import { Button } from 'flowbite-react';
import logo from '../../logo.png';


interface Props{
    inboxItem: any,
    backButton: ()=> void,
}

const Messages: React.FC<Props> = ({inboxItem, backButton}) => {

    // console.log(inboxItem)

    const { accountCTX } = useContextState();
    const { profile } = accountCTX

    const { getChatSocket } = useMessagesWs();
    const { sendMessage } = useMessagesApi();
    const { setTokens } = useShared();

    const [currentSubView, setCurrentSubView] = useState<string>('loader');
    const [loaded, setLoaded] = useState<boolean>(false);
    const [subject, setSubject] = useState<string>();
    const [inboxIcon, setInboxIcon] = useState<string>();
    const [message, setMessage] = useState<string>('');
    const [messages, setMessages] = useState<any[]>([]);
    const ws = useRef<any>(null)
    const messagesEndRef = useRef<null | HTMLDivElement>(null)

    const { inboxId } = useParams()

    var messageDate:string = ''
    // const inboxId = inboxItem.id

    const onSend = async (message: string, picture: File | null, audio: File | null) => {
        // console.log('sent')
        // console.log('message: ', message)
        if (inboxId){
            var formData = new FormData();
            formData.append('inboxId', inboxId)
            formData.append('message', message)
            formData.append('type', 'text')
            // setCurrentSubView('loader')
            // const data = { id: id }
            
            const apiCall = await sendMessage(formData);
            if (apiCall && apiCall.res === 'success'){
                // console.log('Message sent ... ')
            }else{alert('Sorry, an error occured ...')}
        }else{alert('Sorry, an error occured ...')}
        
    };


    const scrollToBottom:any = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }
    // console.log(profile)

    useEffect(()=>{
        if (!loaded){
            setLoaded(true)
            const chatSocket = getChatSocket(inboxId)
            // console.log('first step')
            // console.log(chatSocket)
            
            chatSocket.onopen = function(){
                // console.log('connection made ...')

                chatSocket.send(JSON.stringify({
                    'type': 'get_messages'
                }));

                chatSocket.send(JSON.stringify({
                    'type': 'join_room'
                }));

                ws.current = chatSocket
            }
        
            chatSocket.onmessage = function (e:any) {
                const data = JSON.parse(e.data);
                // console.log(data);
        
                switch (data.type) {
                    case "jwt_tokens":
                        setTokens(data.token, data.refreshToken)
                        break;

                    case "chat.message":
                        setMessages(messages => (messages.some(message => message.id === data.message.id))? 
                            (messages.map((message:any)=>{return (message.id === data.message.id)?data.message : message})) : [...messages, data.message]
                        )
                        if (profile.username === data.message.username){
                            scrollToBottom()
                        }
                        break;
        
                    case "messages":
                        setMessages(data.messages)
                        setCurrentSubView('default')
                        scrollToBottom()
                        break;

                    case "user_list":
                        for (let i = 0; i < data.users.length; i++) {
                            
                            // onlineUsersSelectorAdd(data.users[i]);
                        }
                        break;

                    case "user_join":
                        setSubject(data.inbox.subject)
                        setInboxIcon(data.inbox.icon)
                        // chatLog.value += data.user + " joined the room.\n";
                        // onlineUsersSelectorAdd(data.user);
                        break;

                    case "user_leave":
                        // chatLog.value += data.user + " left the room.\n";
                        // onlineUsersSelectorRemove(data.user);
                        break;
                    default:
                        console.error("Unknown message type!");
                        break;
                }
        
                // scroll 'chatLog' to the bottom
                // chatLog.scrollTop = chatLog.scrollHeight;
            };
        
            chatSocket.onclose = function(e:any) {
                // console.error('Chat socket closed unexpectedly: ', e);
                console.log('Chat socket closed')
                backButton()
            };

            chatSocket.onerror = function(e:any) {
                // console.error('Error: ', e);
            };

        }

    },[loaded, getChatSocket, inboxItem.id, inboxId])



    return (
        <div>
            <div className="fixed mx-auto inset-x-0 h-[calc(100vh-74px)] sm:w-1/2 ">
                <div className="flex rounded-lg right-0 flex-1 p:2 sm:p-6 justify-center flex-col max-h-[50rem] ">
                    <div className=" top-20 w-full  rounded-lg flex bg-gray-900 sm:items-center justify-between py-3 border-b-2 border-gray-200 ">

                        <div className="flex items-center space-x-2">
                            <button type="button" className="ml-6 inline-flex items-center justify-center rounded-lg border h-10 w-10 transition duration-500 ease-in-out text-gray-100 bg-gray-300 hover:bg-gray-100 focus:outline-none">
                                <IoIosArrowRoundBack className="h-6 w-12 text-gray-900 group-hover:text-indigo-600" onClick={()=>{ return ws.current.close();}} />
                            </button>
                        </div>

                        <div className="relative flex items-center space-x-4">
                            <div className="relative">
                                {/* <span className="absolute text-green-500 right-0 bottom-0">
                                    <svg width="20" height="20">
                                    <circle cx="8" cy="8" r="8" fill="currentColor"></circle>
                                    </svg>
                                </span> */}
                                
                                <img src={(inboxIcon)? inboxIcon: logo} alt="" className="w-10 sm:w-16 h-10 sm:h-16 rounded-full" />
                            </div>
                            <div className="flex flex-col leading-tight">
                                <div className="text-2xl mt-1 flex items-center">
                                    <span className="text-gray-100 mr-3">{inboxItem.subject}</span>
                                </div>
                                <span className="text-lg text-gray-100">{subject? subject:"Messages"}</span>
                            </div>
                        </div>

                        <div className="flex items-center space-x-2">
                            {/* <button type="button" className="mr-4 inline-flex items-center justify-center rounded-lg border h-10 w-10 transition duration-500 ease-in-out text-gray-100 bg-gray-300 hover:bg-gray-100 focus:outline-none">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="text-gray-900 h-6 w-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                                </svg>
                            </button> */}
                        </div>
                    </div>
                    

                    {(currentSubView === 'loader')? <div className='mt-6'><Loader /></div>: null }

                    {(currentSubView === 'default')?

                        <div id="messages" className="top-28 rounded-lg sm:w-full flex bg-contain bg-gray-600 bg-[url('assets/images/TransparentLogo.png')] bg-opacity-10 flex-col space-y-4 p-3 h-[calc(100vh-250px)] overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch">
                            {   messages.map((messageItem:any, index:any)=>{
                                    if (messageItem.updated_at.slice(0,10) !== messageDate){
                                        // setMessageDate(messageItem.updated_at.slice(0,10))
                                        messageDate = messageItem.updated_at.slice(0,10)
                                        return <div key={index}>
                                                    <div className="mt-3 w-full flex justify-between items-center ">
                                                        <hr className="w-28 h-1 bg-gray-300 border-0 rounded md:my-10 dark:bg-gray-700" />
                                                        <span className="text-md">{messageItem.updated_at.slice(0,10)}</span>
                                                        <hr className="w-28 h-1 bg-gray-300 border-0 rounded md:my-10 dark:bg-gray-700" />
                                                    </div>
                                                    <SingleMessage  message={messageItem} />
                                                </div>
                                    }
                                    else{
                                        return <SingleMessage key={index} message={messageItem} />
                                    }
                                }) 
                            }
                            <div ref={messagesEndRef} />
                            <Button
                                onClick={()=>{return scrollToBottom()}}
                                type="button"
                                color="gray" 
                                pill
                                className='fixed bottom-36'
                            >
                                <BsChevronDoubleDown size={18}/>
                            </Button>
                        </div>
                        :null
                    }

                    {(currentSubView === 'default')?
                        <div className="fixed mx-auto inset-x-0 bottom-0 w-full sm:w-1/2 border-t-2 bg-gray-200 border-gray-300  pt-4 h-[100px] sm:mb-0 rounded-lg">
                            <ChatInput imageHandler={()=>{return setCurrentSubView('pictureInput')}} onSend={onSend}/>
                        </div>:null
                    }

                    {(currentSubView === 'pictureInput') && (typeof inboxId !== 'undefined')?
                        <div className="rounded-lg flex bg-contain bg-gray-600 bg-[url('assets/images/TransparentLogo.png')] bg-opacity-10 flex-col space-y-4 p-3 h-[calc(100vh-174px)] overflow-y-auto scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch">
                            <ImagesUpload 
                                id={inboxId} 
                                apiFunction={sendMessage} 
                                callBack={()=>{setCurrentSubView('default'); return  }} 
                                maxNumberOfFiles={1} 
                                type='message'
                            />
                        </div>:null
                    }

                    {(currentSubView ==='success')? 
                        <AlertComponent 
                            callBack={() => {setCurrentSubView('default')}} 
                            type='success' 
                            subject='Success' 
                            message={message}
                        />: null
                    } 

                    {(currentSubView ==='error')? 
                        <AlertComponent 
                            callBack={() => {setCurrentSubView('default')}} 
                            type='error' 
                            subject='Failed' 
                            message={message}
                        />: null
                    } 
                </div>
            </div>
        </div>
    )
}

export default Messages