
import Footer from '../components/footers/Footer'
import ApplicationBar from '../components/shared/ApplicationBar';
import { useGlobalModalContext, MODAL_TYPES } from '../components/modals/GlobalModal'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { useEventApi } from '../store';
import Tickets from '../components/events/Tickets';


const ProfilePage = () => {
  const { store, showModal } = useGlobalModalContext();

  const { profileId } = useParams()

  const [currentSubView, setCurrentSubView] = useState<string>('default');
  const [message, setMessage] = useState<string>('')

  const [openModal, setOpenModal] = useState<string | undefined>();
  const props = { openModal, setOpenModal };

  const profileModal = (profileId:string) => {
    props.setOpenModal('initial-focus');
    showModal(MODAL_TYPES.PROFILE_MODAL, { title: "Create instance form", confirmBtn: "Close", profileType:'guest', profileId:profileId });
  };

  document.title = `FestiShare: Profile!`

  

  useEffect(() => {
    // console.log('profileId: ', profileId)
    if (typeof profileId !== 'undefined'){profileModal(profileId)}
    
  }, [profileId])

  return (
    <div >
      {/* { tickets && (currentSubView === 'default')? 
        <div>
          <Tickets eventId={eventId} eventName={eventName} tickets={tickets} />
        </div>:null
      }
      { (currentSubView === 'error')?
        <div className="bg-red-200">
          <p>{message}</p>
        </div>:null
      } */}
      <Footer />
      <ApplicationBar />
    </div>
  )
}

export default ProfilePage