import React, {useState, useRef } from 'react'
import { IoIosArrowRoundBack } from 'react-icons/io';
import  { Button, Checkbox, Label, Modal, TextInput, Datepicker, Select } from 'flowbite-react';
import { useGlobalModalContext } from './GlobalModal';
import { useContextState } from '../../context/Context';
import logo from '../../logo.png';
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber} from 'react-phone-number-input';
import { useAuth } from "../../store";
import Loader from '../shared/Loader'
import AlertComponent from '../shared/AlertComponent';

interface Props{
    back?:() => void;
    closeButton?:() => void;
    close: boolean;
    callBack?: () => void;
}

const regex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

const LoginModal: React.FC<Props> = ({callBack, close, back, closeButton}) => {
    const { hideModal, store } = useGlobalModalContext();
    const { modalProps } = store || {};
    const { title, confirmBtn } = modalProps || {};

    const { accountCTX } = useContextState();
    const { signedIn, setSignedIn } = accountCTX

    const [displayLoader, setDisplayLoader] = useState<boolean>(false);

    const [message, setMessage] = useState<string>('Sorry, an error occured ...');

    const [emailExists, setEmailExists] = useState<boolean>(false);
    const [isdisabled, setdisability] = useState<boolean>(true);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const [checkEmail, setCheckEmail] = useState<boolean>(true);
    const [login, setLogin] = useState<boolean>(false);
    const [signup, setSignup] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [username, setUsername] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string | undefined>('');
    const [emailConfirmation, setEmailConfirmation] = useState<boolean>(false);
    const [signupEmailConfirmation, setSignupEmailConfirmation] = useState<string>('');
    const [passwordRecovery, setPasswordRecovery] = useState<boolean>(false);
    const [validUsername, setValidUsername] = useState<boolean>(false);
    const [samePassword, setSamePassword] = useState<boolean>(false);

    const { checkAccount, loginCall, sendCodeViaEmail, validateCodeViaEmail, resetPassword, signupCall } = useAuth();

    const [underAge, setUnderAge] = useState<boolean>(true);

    const [openModal, setOpenModal] = useState<string | undefined>();
    const emailInputRef = useRef<HTMLInputElement>(null)
    const props = { openModal, setOpenModal, emailInputRef };

    const [currentLoginView, setCurrentLoginView] = useState<string>('default');


    const handleModalToggle = () => {
        hideModal();
    };


    async function validateUsername(usernameVar:string) {
        const apiCall:any = await checkAccount({username: usernameVar});
        if (!apiCall) {setValidUsername(true);}
        else {setValidUsername(false); setdisability(true)}
    }


    const handleUsername = (e: React.FormEvent<HTMLInputElement>) => {
        const usernameVar = e.currentTarget.value;
        setUsername(usernameVar);
        if (usernameVar.length > 3 && usernameVar.length < 16){
            validateUsername(usernameVar);
        }else {setValidUsername(false); setdisability(true);console.log('lol2')}
    }


    const handleBirthDateChange = (date: any) => {
        var today = new Date().toJSON().slice(0,10).replace(/-/g,'/');
        var birth = new Date(date).toJSON().slice(0,10).replace(/-/g,'/');
        // console.log('test: ', (parseInt(today)-parseInt(birth)))
        if (parseInt(today)-parseInt(birth) < 18){
            setdisability(true)
            setUnderAge(true)
        }
        else{
            setUnderAge(false)
        }
    }

    
    const handlePasswordRecovery = async () => {
        const apiCall = await sendCodeViaEmail({username: email});
        console.log(apiCall)
        if (apiCall) {setLogin(false); setEmailConfirmation(true);}
        else {alert('There was an error sending the code to your email ...')}
    }

    const isOver18 = (birth:Date) => {
        var today = new Date().toJSON().slice(0,10).replace(/-/g,'/');
        var birthDate = new Date(birth).toJSON().slice(0,10).replace(/-/g,'/');
        // console.log('test: ', (parseInt(today)-parseInt(birth)))
        if (parseInt(today)-parseInt(birthDate) < 18){
            setdisability(true)
            setUnderAge(true)
            return false
        }
        else{
            setUnderAge(false)
            return true
        }
    }


    const handleFormChange = async (e: React.FormEvent<HTMLFormElement>) => {
        e.persist();
        // console.log(login)

        if (checkEmail){
            setdisability(e.currentTarget.email.value.length < 4);
            setEmail(e.currentTarget.email.value)
        }

        else if (login){
            setdisability((e.currentTarget.password.value.length < 6))
        }

        else if (emailConfirmation){
            setdisability((e.currentTarget.emailConfirmation.value.length < 6))
        }

        else if (passwordRecovery){

            if (e.currentTarget.recoveryPassword.value !== e.currentTarget.confirmRecoveryPassword.value){
                setdisability(true)
                setSamePassword(false)
            }
            else if (e.currentTarget.recoveryPassword.value.length < 6){
                setdisability(true)
            }
            else {
                setSamePassword(true);
                setdisability(false);
            }
        }
        
        else if (signup){

            const samePassword = e.currentTarget.signupPassword.value === e.currentTarget.confirmSignupPassword.value;
            const validPhoneNumber = isValidPhoneNumber(e.currentTarget.phone.value);
            const passwordLengthValid = e.currentTarget.signupPassword.value.length >= 6;
            const agreementChecked = e.currentTarget.agreement.checked;
            const namesFilled = e.currentTarget.lastName.value !== '' && e.currentTarget.firstName.value !== '';
            const birthDateValid = !e.currentTarget.birthDate.value || isOver18(e.currentTarget.birthDate.value); // Assuming a function for age check

            const disability = !samePassword || !validUsername || !validPhoneNumber || !passwordLengthValid || !agreementChecked || !namesFilled || !birthDateValid;

            setSamePassword(samePassword);
            setdisability(disability);
            setUnderAge(!birthDateValid);                        
        }
    };


    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setDisplayLoader(true)

        if (checkEmail){
            setCheckEmail(false)
            const accountCheck = await checkAccount({username: email,});
            setDisplayLoader(false)
            setdisability(true)
            if (accountCheck){setEmailExists(accountCheck);setLogin(true)}
            else {
                if (regex.test(email)){setEmailExists(accountCheck);setSignup(true)}
                else {setCheckEmail(true);alert('Sorry, we did not find an account matching this pseudo. Kindly check the pseudo or provide an email to continue...')}
            }
        }

        else if (login){
            setLogin(false);
            const apiCall = await loginCall({username: email,password: e.currentTarget.password.value});
            setDisplayLoader(false)
            console.log(apiCall)
            if (apiCall) {
                setdisability(true);
                if (apiCall==='active'){setCurrentLoginView('success');setSignedIn(true);}
                else if (apiCall==='deleted'){
                    setLogin(true); 
                    setMessage('Sorry, you have deleted your account ... To recover your account, Kindly reset your password on the login page.'); 
                    setCurrentLoginView('error');}
                else if (apiCall === 'email_verification'){handlePasswordRecovery();setEmailConfirmation(true);setSignupEmailConfirmation('signup')}
            }else{
                setLogin(true); 
                setMessage('Incorrect Credentials ...'); 
                setCurrentLoginView('error');
            }
            
        }

        else if (emailConfirmation){
            setEmailConfirmation(false);
            const apiCall = await validateCodeViaEmail({code: e.currentTarget.emailConfirmation.value,type: signupEmailConfirmation});
            setDisplayLoader(false)
            if (apiCall) {
                if (signupEmailConfirmation ==='signup'){setCurrentLoginView('success');setSignedIn(true);}
                else{setPasswordRecovery(true);}
            }
            else {alert('Wrong code ...');setEmailConfirmation(true);}
        }

        else if (passwordRecovery){
            setPasswordRecovery(false);
            const apiCall = await resetPassword({
                password: e.currentTarget.recoveryPassword.value
            });
            setDisplayLoader(false)
            console.log(apiCall)
            if (apiCall) {
                alert('Congratulations, you have successfully updated your password. Kindly login ...');
                
                setLogin(true);
            }
            else {
                setPasswordRecovery(true);
                alert('Sorry, there was an error ...')
            }
            
        }

        else if (signup){
            setSignup(false);
            var today = new Date().toJSON().slice(0,10).replace(/-/g,'/');
            var birth = new Date(e.currentTarget.birthDate.value).toJSON().slice(0,10).replace(/-/g,'/');

            if (parseInt(today)-parseInt(birth) < 18){
                console.log('You must be at least 18 to signup')
                alert("You must be at least 18 to signup")
            }
            
            else {
                const apiCall = await signupCall({
                    email: email,
                    username: e.currentTarget.username.value as string,
                    firstName: e.currentTarget.firstName.value as string,
                    lastName: e.currentTarget.lastName.value as string,
                    phone: e.currentTarget.phone.value as string,
                    birth: new Date(e.currentTarget.birthDate.value).toJSON().slice(0,10),
                    gender: e.currentTarget.gender.value as string,
                    password: e.currentTarget.signupPassword.value as string
                });
                setDisplayLoader(false)
                console.log(apiCall)
                if (apiCall) {
                    setDisplayLoader(true)
                    setSignupEmailConfirmation('signup')
                    setdisability(true); 
                    const apiCall = await sendCodeViaEmail({
                        username: email
                    });
                    setDisplayLoader(false)
                    console.log(apiCall)
                    console.log('signed Up') 
                    if (apiCall) {
                        setEmailConfirmation(true);
                    }
                    else {
                        alert('There was an error sending the code to your email ...')
                    }
                }
                else {setSignup(true); alert('Sorry, there was an error ...')}
            }                     
        }

        // const postData = new FormData();
        // postData.append('email', e.currentTarget.email.value);
        // postData.append('email', e.currentTarget.password.value);
    };

    
    return (
        <div>
            <Modal
                // show={props.openModal === 'initial-focus'}
                show={true}
                size="md"
                popup
                onClose={()=> {return closeButton? closeButton():handleModalToggle()}}
                // onClose={handleModalToggle}
                // initialFocus={props.emailInputRef}
            >
                <Modal.Header>{back?<IoIosArrowRoundBack className="h-6 w-12 text-gray-600 group-hover:text-indigo-600" onClick={back} />:null}</Modal.Header>
                <Modal.Body>
                    {(currentLoginView ==='default')? 
                        <form onSubmit={handleSubmit} onChange={handleFormChange} >
                            <div className="space-y-6">
                                <div className="w-full flex justify-center ">
                                    <img className="flex justify-center shadow rounded-xl" src={logo} alt="Logo" width={63} />
                                </div>
                                <h3 className="flex justify-center text-lg font-medium text-gray-900 dark:text-white">FestiShare</h3>
                                <div>
                                    <div className="mb-2 block">
                                        <Label htmlFor="email" value="Kindly provide your Email or Username to continue" />
                                    </div>
                                    <TextInput 
                                        id="email" 
                                        ref={props.emailInputRef} 
                                        placeholder="name@domain.com" 
                                        defaultValue={email}
                                        disabled={emailExists === true ? true : false} 
                                        // onChange={(e) => checkemail(e)}
                                        required 
                                    />
                                </div>

                                { displayLoader? 
                                    <Loader />: null
                                }
                                { login &&  
                                    <div>
                                        
                                        <div>
                                            <div className="mb-2 block">
                                                <Label htmlFor="password" value="Your password" />
                                            </div>
                                            <TextInput 
                                                type={isPasswordVisible ? "text" : "password"} 
                                                id="password" 
                                                // onChange={(e) => checkpassword(e)} 
                                                required 
                                            />
                                        </div>
                                        
                                        <div className="flex justify-between mt-2">
                                            <div className="flex items-center gap-2">
                                                <Checkbox id="seePassword" onClick={()=>{isPasswordVisible ? setIsPasswordVisible(false):setIsPasswordVisible(true)}}/>
                                                <Label htmlFor="seePassword">See Password</Label>
                                            </div>
                                            <a href="#" onClick={handlePasswordRecovery} className="text-sm text-cyan-700 hover:underline dark:text-cyan-500">
                                                Lost Password?
                                            </a>
                                        </div> 
                                    </div>
                                }
                                
                                {/* { !emailExists ? 
                                    <div className="w-full flex justify-center">
                                        <Button type='button' disabled={isdisabled} onClick={handleEmailCheck}>Continue {emailExists}</Button>
                                    </div>: null
                                } */}

                                { signup ? 
                                    <div>
                                        <div className="border-b border-gray-900/10 pb-12">
                                            <h2 className="text-base font-semibold leading-7 text-gray-900">Personal Information</h2>
                            
                                            <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                                <div className="sm:col-span-3">
                                                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                                        Choose a username (between 4 and 15 characters)
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            type="text"
                                                            name="username"
                                                            id="username"
                                                            minLength={4}
                                                            maxLength={15}
                                                            autoComplete="username"
                                                            required
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            value={username}
                                                            onChange={handleUsername} 
                                                        />
                                                        <div className='flex justify-center'>
                                                            { validUsername? 
                                                                <h6 className='text-green-900'>Username available</h6>:<h6 className='text-red-900'>Username not available</h6>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="sm:col-span-3">
                                                    <label htmlFor="first-name" className="block text-sm font-medium leading-3 text-gray-900">
                                                        First name
                                                    </label>
                                                    <div className="mt-2">
                                                        <input
                                                            type="text"
                                                            name="first-name"
                                                            id="firstName"
                                                            autoComplete="given-name"
                                                            required
                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                </div>
                        
                                                <div className="sm:col-span-3">
                                                    <label htmlFor="last-name" className="block text-sm font-medium leading-3 text-gray-900">
                                                        Last name
                                                    </label>
                                                    <div className="mt-2">
                                                    <input
                                                        type="text"
                                                        name="lastName"
                                                        id="last-name"
                                                        autoComplete="family-name"
                                                        required
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                    </div>
                                                </div>
                        
                                                <div className="sm:col-span-4">
                                                    <label htmlFor="phone" className="block text-sm font-medium leading-3 text-gray-900">
                                                        Phone Number
                                                    </label>
                                                    <div className="mt-2">
                                                        <PhoneInput
                                                            id='phone'
                                                            required
                                                            international
                                                            countryCallingCodeEditable={false}
                                                            className="px-3 py-2 bg-white border shadow-sm border-slate-300 w-full rounded-md sm:text-sm"
                                                            defaultCountry="US"
                                                            value={phoneNumber}
                                                            onChange={setPhoneNumber} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="border-b border-gray-900/10 pb-6 mb-6 mt-6">
                                            <div className="mb-2 block">
                                                <Label htmlFor="birthDate" value="Your date of birth" />
                                            </div>
                                            <Datepicker onSelectedDateChanged={(date) => handleBirthDateChange(date)} id='birthDate' title="Pick your Date of Birth " datepicker-format="mm/dd/yyyy" required/>
                                            <div className='flex justify-center'>
                                                { underAge? 
                                                    <h6 className='text-red-900'>You need to be at least 18 years old</h6>:null
                                                }
                                            </div>
                                        </div>

                                        <div className="border-b border-gray-900/10 pb-6 mb-6 mt-6">
                                            <div className="mb-2 block">
                                                <Label htmlFor="gender" value="Select your gender" />
                                            </div>

                                            <Select
                                                id="gender"
                                                required
                                            >
                                                <option></option>
                                                <option>F</option>
                                                <option>M</option>
                                            </Select>
                                        </div>

                                        <div className="border-b border-gray-900/10 pb-6 mt-6">
                                            <div className="mb-2 block">
                                                <Label htmlFor="signupPassword" value="Your password" />
                                            </div>
                                            <TextInput 
                                                type={isPasswordVisible ? "text" : "password"} 
                                                id="signupPassword" 
                                                required 
                                            />

                                            <div className="mb-2 block">
                                                <Label htmlFor="confirmSignupPassword" value="Confirm your password" />
                                            </div>
                                            <TextInput 
                                                type={isPasswordVisible ? "text" : "password"} 
                                                id="confirmSignupPassword" 
                                                required 
                                            />
                                            <div className='flex justify-center'>
                                                { samePassword? 
                                                    <h6 className='text-green-900'>Same Password</h6>:<h6 className='text-red-900'>Both passwords do not match or not valid</h6>
                                                }
                                            </div>
                                            <div className="flex justify-center mt-2">
                                                <Checkbox id="seePassword" onClick={()=>{isPasswordVisible ? setIsPasswordVisible(false):setIsPasswordVisible(true)}}/>
                                                <Label className='ml-2' htmlFor="seePassword">See Passwords</Label>
                                            </div>
                                        </div>

                                        <div className="mt-6 mb-2 block">
                                            <Label htmlFor="referral" value="Referral Code (Optional)" />
                                        </div>
                                        <TextInput 
                                            type={"text"} 
                                            id="referral"
                                        />

                                        <div className="mt-10 space-y-10">
                                            <fieldset>
                                                <legend className="text-sm font-semibold leading-6 text-gray-900">Agreement</legend>
                                                <div className="mt-3 space-y-6">
                                                    <div className="relative flex gap-x-3">
                                                        <div className="flex h-6 items-center">
                                                            <input
                                                                id="agreement"
                                                                name="agreement"
                                                                type="checkbox"
                                                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                                required
                                                            />
                                                        </div>
                                                        <div className="text-sm leading-6">
                                                            <label htmlFor="comments" className="font-medium text-gray-900">
                                                                I AGREE THAT I READ, UNDERSTOOD, AND ACCEPT THE USER AGREEMENT PROVIDED IN THE LINK BELOW.
                                                            </label>
                                                            <p className="text-gray-500" >
                                                                <a href="#"><u>User Agreement</u></a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>


                                    </div>: null
                                }

                                {emailConfirmation? 
                                    <div>
                                        <div className="mb-2 block">
                                            <Label htmlFor="emailConfirmation" value="Kindly enter the code we sent you via email (make sure to also check your junk folder if you did not find it in your mailbox)" />
                                        </div>
                                        <TextInput 
                                            type={"text" } 
                                            id="emailConfirmation" 
                                            required 
                                        />
                                    
                                    </div>: null
                                }

                                {passwordRecovery?
                                    <div>
                                        <div className="border-b border-gray-900/10 pb-6">
                                            <div className="mb-2 block">
                                                <Label htmlFor="recoveryPassword" value="Your new password" />
                                            </div>
                                            <TextInput 
                                                type={isPasswordVisible ? "text" : "password"} 
                                                id="recoveryPassword" 
                                                required 
                                            />

                                            <div className="mb-2 block">
                                                <Label htmlFor="confirmRecoveryPassword" value="Confirm your new password" />
                                            </div>
                                            <TextInput 
                                                type={isPasswordVisible ? "text" : "password"} 
                                                id="confirmRecoveryPassword" 
                                                required 
                                            />

                                            <div className='flex justify-center'>
                                                { samePassword? 
                                                    <h6 className='text-green-900'>Same Password</h6>:<h6 className='text-red-900'>Both passwords do not match or not valid</h6>
                                                }
                                            </div>
                                            <div className="flex justify-center mt-2">
                                                <Checkbox id="seePassword" onClick={()=>{isPasswordVisible ? setIsPasswordVisible(false):setIsPasswordVisible(true)}}/>
                                                <Label className='ml-2' htmlFor="seePassword">See Passwords</Label>
                                            </div>
                                        </div>
                                    </div>: null
                                }

                                <div className="w-full flex justify-center mt-6">
                                    <Button type='submit' disabled={isdisabled} >Continue</Button>
                                </div>

                                {/* <div className="flex justify-between text-sm font-medium text-gray-500 dark:text-gray-300">
                                    Not registered?&nbsp;
                                    <a href="/modal" className="text-cyan-700 hover:underline dark:text-cyan-500">
                                        Create account
                                    </a>
                                </div> */}
                            </div>
                        </form>:null
                    }

                    {(currentLoginView ==='success')? 
                        <AlertComponent 
                            callBack={() => {
                                if (close){return hideModal()}
                                else if (callBack){return callBack()}
                                else {return setCurrentLoginView('')}
                            }} 
                            type='success' 
                            subject='Success' 
                            message='Congratulations, you have successfully logged in ...' 
                        />: null
                    } 

                    {(currentLoginView ==='error')? 
                        <AlertComponent 
                            callBack={() => {setCurrentLoginView('default')}} 
                            type='error' 
                            subject='Failed' 
                            message={message}
                        />: null
                    } 
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default LoginModal