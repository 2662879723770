import { Button, Toast } from 'flowbite-react'
import React, { useState } from 'react'
import { useContextState } from '../../context/Context';
import logo from '../../logo.png'
import { HiCheck } from 'react-icons/hi';
import { IoIosNotificationsOutline } from "react-icons/io";
import { GetNewToken, OnMessageListener } from '../../firebase';

const NotificationHandler = () => {
    const { accountCTX } = useContextState();
    const { signedIn } = accountCTX

    const [show, setShow] = useState<boolean>(false);
    const [notification, setNotification] = useState<any>({title: '', body: 'Notification active ...'});
    const [isTokenFound, setTokenFound] = useState<boolean>(false);
    const [isLoaded, setIsLoaded] = useState<boolean>(false)
    const [deviceType, setDeviceType] = useState<string>('')
    const [showNotificationRequestButton, setShowNotificationRequestButton] = useState<boolean>(false)
    // const [notificationIsSupported, setNotificationIsSupported] = useState<boolean>(false)

    const handlePermission = (permission:any) => {
        // alert(permission);
        // set the button to shown or hidden, depending on what the user answers
        if (permission === "granted") {
            // console.log('Notifications granted');
            setShowNotificationRequestButton(false)
            GetNewToken(setTokenFound);;
        }
    }

    const askNotificationPermission = () => {
        // console.log('askNotificationPermission')

        handlePermission(Notification.permission);
        // check if Safari
        // https://kevinbasset.medium.com/sending-push-notifications-with-safari-16-on-macos-ventura-f099dc0e7274
        if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {
            // alert('using Safari')
            setDeviceType('Apple')
        } 
    }

    const checkNotificationPromise = () => {
        try {
            Notification.requestPermission().then();
        } catch (e) {
            return false;
        }
        return true;
    }

    const checkNotificationRequestStatus = () =>{
        //check if user responded to notification
        if (typeof(Storage) !== "undefined" && localStorage.getItem("notification_request")) {  
            var notification_request = localStorage.getItem("notification_request");

            if (notification_request === 'Yes'){
                // console.log('calling FCM')
                GetNewToken(setTokenFound);
            }
            else if (notification_request === 'set'){
                // console.log("notification tok already set");
            }
            else {
                setShowNotificationRequestButton(true)
                askNotificationPermission();
            }
        }
        else if (typeof(Storage) !== "undefined"){
            setShowNotificationRequestButton(true)
            askNotificationPermission();
        }
    }

    const handleNotificationButton = () => {
        // console.log(Notification.permission);

        // Let's check if the browser supports notifications
        if (!("Notification" in window)) {
            console.log("This browser does not support notifications.");
        } else if (checkNotificationPromise()) {
            Notification.requestPermission().then((permission) => {
                // alert('1')
                handlePermission(permission);
            });
        } else {
            // alert('2')
            Notification.requestPermission((permission) => {
                handlePermission(permission);
            });
        }

        localStorage.setItem("notification_request", "Yes");
    }


    const handleAppleNotificationButton = () => {
        if (navigator.serviceWorker && window.PushManager && window.Notification) {
            // Request permission to send push notifications
            if (checkNotificationPromise()) {
                window.Notification.requestPermission().then((permission) => {
                    handlePermission(permission);
                });
            } else {
                window.Notification.requestPermission((permission) => {
                    handlePermission(permission);
                });
            } 
        }
        else {
            // alert("Kindly update you phone to the latest IOS version (16.4 minimum) to be able to receive notifications")
            // the device does not support the Web Push API
            setShowNotificationRequestButton(false)
        }

        localStorage.setItem("notification_request", "Yes");
    }


    if (!isLoaded && !isTokenFound && signedIn){
        if (("Notification" in window) || ('Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window)){
            // console.log('Notification in Window')
            checkNotificationRequestStatus();
        }
        // console.log('lol')
        setIsLoaded(true);
    }
    else{
        // console.log('lol1', isLoaded, isTokenFound)
    }
    
    OnMessageListener().then((payload:any) => {
        setShow(true);
        setNotification({title: payload.notification.title, body: payload.notification.body})
        // console.log(payload);
    }).catch((err:any) => {
        console.log('failed: ', err)
    });


    return (
        <div className='fixed top-[180px] right-[15px] w-64 max-w-[320px] z-5'>
            {show &&
                <Toast >
                
                    <div className='flex items-start'>
                        <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-cyan-100 text-cyan-500 dark:bg-cyan-900 dark:text-cyan-300">
                            <IoIosNotificationsOutline className="h-5 w-5" />
                        </div>
                        <div className="ml-3 text-sm font-normal">
                            <span className="mb-1 text-sm font-semibold text-gray-900 dark:text-white">{notification.title}</span>
                            <div className="mb-2 text-sm font-normal">{notification.body}</div>
                            
                            <div className="flex-start flex gap-2">
                                {/* <div className="w-auto">
                                <Button size="xs">Update</Button>
                                </div> */}
                                <div className="w-auto">
                                    <Button color="light" size="xs" onClick={() => setShow(false)}>
                                        Hide
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {/* <div className='flex items-start '>
                            <Toast.Toggle  onDismiss={() => setShow(false)} />
                        </div> */}
                    </div>
                
                </Toast>
            }

            {showNotificationRequestButton? 
                <Button
                    className='fixed top-[150px] right-[5px] z-5'
                    onClick={()=> {return (deviceType === 'Apple')? handleAppleNotificationButton():handleNotificationButton()}}
                    type="button"
                    gradientDuoTone="purpleToBlue" 
                    outline
                    pill
                >   
                    <IoIosNotificationsOutline  size={'27px'}/>
                    Allow Notifications
                </Button>:null
            }

        </div>
    )
}

export default NotificationHandler