import { useEffect} from 'react';
import { useApi } from "./api"
import { LoginDTO, RegisterDTO, UploadDocumentDTO, CheckAccountDTO } from "../models"
import { useContextState } from '../context/Context';
import Cookies from 'js-cookie';



export const useAccountApi = () => {
    const { accountCTX } = useContextState();
    const { signedIn, setSignedIn, profile, setProfile } = accountCTX
    const { $post, $get } = useApi()

    const getAccountTransactions = async (id?:string) => {
        var path = 'account/transactions/'
        if (id){path = path + id + '/'}
        const data = await $get(path, true)
        // if (data.res !== 'success'){return }
        return data
    }

    
    const getMyCalendar = async (credentials: {year?: string, month?:string}) => {
        const data = await $post('account/my_calendar/', credentials, true)
        // console.log(data)
        if (data && (data.res === 'success' || data.res === 'failed')){
            return data
        }
        return 
    }

    


    return { getAccountTransactions, getMyCalendar }
}
