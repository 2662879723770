import React, { useState, useRef } from 'react'
import { useGlobalModalContext, MODAL_TYPES } from './GlobalModal';
import { useContextState } from '../../context/Context';
import { useEventApi } from "../../store";
import  { Button, Modal, TextInput, Textarea, Label, Select } from 'flowbite-react';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import { EventTicketPackage } from '../../models';
import EventTicketPrice from '../events/EventTicketPrice';
import Loader from '../shared/Loader';
import AlertComponent from '../shared/AlertComponent';
import LoginModal from './LoginModal';
import PaypalCheckout from '../paypal/PaypalCheckout';
import Tickets from '../events/Tickets';


const regex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

const GetEventPackageModal = () => {
    const { hideModal, store } = useGlobalModalContext();
    const { modalProps } = store || {};
    const { title, confirmBtn, eventPackage, eventId, eventName, premium } = modalProps || {};

    const { eventsCTX, accountCTX } = useContextState();
    const { signedIn } = accountCTX

    const { sendEventTicketRequest, getNumberOfTicketsThatCanBeBought, generatePendingTransactionCode, createIncomingPaypalTransaction, createIncomingFreeTransaction } = useEventApi();
    
    const [loaded, setLoaded] = useState<boolean>(false);

    const [tickets, setTicket] = useState<any[]>([]);
    const [message, setMessage] = useState<string>('');
    const [pendingTransactionId, setPendingTransactionId ] = useState<string>('');
    const [currentSubView, setCurrentSubView] = useState<string>('edit');
    const [email, setEmail] = useState<string>('');
    const [emailView, setEmailView] = useState<boolean>(false);
    const [isdisabled, setIsdisabled] = useState<boolean>(true);
    
    const [total, setTotal] = useState<number>(0);
    const [totalGuest, setTotalGuest] = useState<number|string>(0);
    

    const [menQuantityValue, setMenQuantityValue] = useState<number>(0);
    const [ladiesQuantityValue, setLadiesQuantityValue] = useState<number>(0);
    const [ticketsBought, setTicketsBought] = useState<number>(0);
    const [minMenTicketsThatCanBeBought, setMinMenTicketsThatCanBeBought] = useState<number>(0);
    const [minLadiesTicketsThatCanBeBought, setMinLadiesTicketsThatCanBeBought] = useState<number>(0);
    const [maxMenTicketsThatCanBeBought, setMaxMenTicketsThatCanBeBought] = useState<number>(0);
    const [maxLadiesTicketsThatCanBeBought, setMaxLadiesTicketsThatCanBeBought] = useState<number>(0);
    const [maxTicketsThatCanBeBought, setMaxTicketsThatCanBeBought] = useState<number>(0);
    // const [menTicketsAvailable, setMenTicketsAvailable] = useState<number>(eventPackage.maxTicketsPerUser + 1);
    // const [ladiesTicketsCount, setLadiesTicketsAvailable] = useState<number>(eventPackage.maxTicketsPerUser + 1);
    

    
    // if (eventPackage.availableTickets < 6 && premium === 'no'){
    //     setLadiesTicketsAvailable(eventPackage.availableTickets + 1)
    //     setMenTicketsAvailable(eventPackage.availableTickets + 1)
    // }

    const getMaxTicketThatCanBeBought = async () => {
        setCurrentSubView('loader')
        setLoaded(true)

        const data = {
            transactionType: 'ticket', 
            id: eventPackage.id,
        }
        
        const apiCall = await getNumberOfTicketsThatCanBeBought(data);
                
        if (apiCall && apiCall.res === 'success'){
            setTicketsBought(apiCall.data.number_of_tickets_bought)
            setMinMenTicketsThatCanBeBought(apiCall.data.min_men_tickets_that_can_be_bought);
            setMaxMenTicketsThatCanBeBought(apiCall.data.max_men_tickets_that_can_be_bought);
            setMinLadiesTicketsThatCanBeBought(apiCall.data.min_ladies_tickets_that_can_be_bought);
            setMaxLadiesTicketsThatCanBeBought(apiCall.data.max_ladies_tickets_that_can_be_bought);
            setMaxTicketsThatCanBeBought(apiCall.data.remaining_tickets_that_can_be_bought)

            if (apiCall.data.min_men_tickets_that_can_be_bought > 0 || apiCall.data.min_ladies_tickets_that_can_be_bought > 0){
                setIsdisabled(false)
                // compute cost
                const totalValue = (apiCall.data.min_men_tickets_that_can_be_bought * eventPackage.priceMen) + (apiCall.data.min_ladies_tickets_that_can_be_bought * eventPackage.priceLadies)
                setTotal(totalValue)

                var totalGuestValue = '0'
                if(totalValue > 0){
                    totalGuestValue = (totalValue*1.1).toString()
                }
                else {
                    totalGuestValue = '0.99'
                }
                
                setTotalGuest(Number(parseFloat(totalGuestValue).toFixed(2)))
            }

            setCurrentSubView('edit')
        }else{setMessage('Sorry, an error occured ...'); setCurrentSubView('error')}
        
    };

    if (!loaded){getMaxTicketThatCanBeBought();}
    
    
    const saveTransaction = async (orderID: string) => {
        // console.log(eventPackage.id);
        const apiCall = await createIncomingPaypalTransaction({
                orderID: orderID,
                packageId: eventPackage.id,
                eventId: eventId,
                email: email,
                transactionType:'ticket',
                transactionId: pendingTransactionId
            }
        );
        if (apiCall && (apiCall.res === 'success')) {
            setTicket(apiCall.data)
            // setMessage('The transaction was successful ...'); 
            setCurrentSubView('tickets')
        }else{setCurrentSubView('error')}
    };

    const saveFreeTransaction = async () => {
        // console.log(eventPackage.id);
        const apiCall = await createIncomingFreeTransaction({
                packageId: eventPackage.id,
                eventId: eventId,
                email: email,
                transactionType:'ticket',
                transactionId: pendingTransactionId
            }
        );
        if (apiCall && (apiCall.res === 'success')) {
            setTicket(apiCall.data)
            // setMessage('The transaction was successful ...'); 
            setCurrentSubView('tickets')
        }else{setCurrentSubView('error')}
    };

    const sendRequest = async () => {
        setCurrentSubView('loader')
        const apiCall = await sendEventTicketRequest({
                packageId: eventPackage.id,
                eventId: eventId,
            }
        );
        if (apiCall && (apiCall.res === 'success')) {
            setTicket(apiCall.data);
            setCurrentSubView('tickets')
        }else{setMessage(apiCall.message); setCurrentSubView('error')}
    };

    const handleLogin = () => {
        return (currentSubView !== 'login')? setCurrentSubView('login'):setCurrentSubView('edit')
    }

    const handleContinueAsGuest = () => {
        setIsdisabled(true)
        return setEmailView(true)
    }

    const handleFormChange = (e: React.FormEvent<HTMLFormElement>) => {
        e.persist();
        const menCount = e.currentTarget.menQuantity.value;
        const ladiesCount = e.currentTarget.ladiesQuantity.value;

        setMenQuantityValue(menCount)
        setLadiesQuantityValue(ladiesCount)

        // compute cost
        const totalValue = (menCount * eventPackage.priceMen) + (ladiesCount * eventPackage.priceLadies)
        setTotal(totalValue)
        var totalGuestValue = '0'
        if(totalValue > 0){
            totalGuestValue = (totalValue*1.1).toString()
        }
        else {
            totalGuestValue = '0.99'
        }
        // const totalGuestValue = (totalValue*1.1).toString()
        setTotalGuest(Number(parseFloat(totalGuestValue).toFixed(2)))
        
        // compute quantities
        const ladiesRest = maxTicketsThatCanBeBought - menCount 
        const menRest = maxTicketsThatCanBeBought - ladiesCount 
        setMaxLadiesTicketsThatCanBeBought(ladiesRest)
        setMaxMenTicketsThatCanBeBought(menRest)

        if ((menCount === '0') && (ladiesCount === '0')){
            setIsdisabled(false)
        }else{
            if (emailView && !regex.test(e.currentTarget.email.value)){setIsdisabled(true)}
            else{setIsdisabled(false)}
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setCurrentSubView('loader')

        setEmail(e.currentTarget.email? e.currentTarget.email.value:'')
        setMenQuantityValue(e.currentTarget.menQuantity.value)
        setLadiesQuantityValue(e.currentTarget.ladiesQuantity.value)

        const data = {
            transactionType: 'ticket', 
            id: eventPackage.id,
            email: e.currentTarget.email? e.currentTarget.email.value:'' ,
            menQuantity: e.currentTarget.menQuantity.value,
            ladiesQuantity: e.currentTarget.ladiesQuantity.value,
        }
        
        const apiCall = await generatePendingTransactionCode(data);
                
        if (apiCall && apiCall.res === 'success'){
            setPendingTransactionId(apiCall.refId)
            setCurrentSubView('payment')
        }else{setMessage(apiCall.message); setCurrentSubView('error')}
        
    };

    const menTicketsLabel = "How many men ? ( $ " + eventPackage.priceMen.toString() + "/person)"
    const ladiesTicketsLabel = "How many ladies ? ( $ " + eventPackage.priceLadies.toString() + "/person)"


    return (
        <div>
            <Modal show={true} size="3xl" onClose={() => hideModal()}>
                <Modal.Header>Get Tickets</Modal.Header>
                <Modal.Body>
                    {(currentSubView ==='login')? <LoginModal callBack={handleLogin} back={handleLogin} close={false} />:null}

                    {(currentSubView ==='loader')? <Loader />:null}
                    {(currentSubView === 'edit')?
                        <form onSubmit={handleSubmit} onChange={handleFormChange}>
                            <div className="border-b border-gray-900/10 pb-6">
                                <h2 className="text-base font-semibold leading-7 text-gray-900">Package: {eventPackage.name}</h2>
                                <h2 className="text-base font-semibold leading-7 text-gray-900">Description: </h2>
                                <p className="mt-1 text-sm leading-6 text-gray-600">{eventPackage.description}</p>
                                <p className="mt-3 text-sm leading-6 text-gray-600">Kindly specify the number and gender categories of tickets that you want to get (You can only get a total of <b>{eventPackage.maxTicketsPerUser}</b> tickets)</p>
                                <p className="mt-1 text-sm leading-6 text-gray-600">Your have already got <b>{ticketsBought}</b> ticket(s) for this event.</p>

                                <div className="mb-2 block mt-6">
                                    <Label htmlFor="packagePriceMen" value={menTicketsLabel} />
                                </div>
                                <Select
                                    id="menQuantity"
                                    required
                                    defaultValue={menQuantityValue}
                                >   
                                    {
                                        [...Array((maxMenTicketsThatCanBeBought + 1)).keys()].map((i, index)=> {
                                            if ((i >= minMenTicketsThatCanBeBought) && (i <= maxMenTicketsThatCanBeBought)){
                                                return <option key={index} >{i}</option>
                                            }
                                        })
                                    }
                                </Select>

                                <div className="mb-2 block mt-6">
                                    <Label htmlFor="packagePriceLadies" value={ladiesTicketsLabel} />
                                </div>
                                <Select
                                    id="ladiesQuantity"
                                    required
                                    defaultValue={ladiesQuantityValue}
                                >   
                                    {
                                        [...Array((maxLadiesTicketsThatCanBeBought + 1)).keys()].map((i, index) => {
                                            if ((i >= minLadiesTicketsThatCanBeBought) && (i <= maxLadiesTicketsThatCanBeBought)){
                                                return <option key={index} >{i}</option>
                                            }
                                        })
                                    }
                                </Select>
                            </div>
                            <h2 className="text-base font-semibold leading-7 text-gray-900">Cost for Festishare account holder: </h2>
                            <p className=" text-sm leading-6 text-gray-600">$ {total} </p>

                            {(premium === 'yes')?
                                <div>
                                    {!signedIn ? 
                                        <div>
                                            <div className="w-full flex justify-center mt-6">
                                                <Button type='button' onClick={handleLogin} >Request</Button>
                                            </div>
                                        </div>
                                        :
                                        <div className="w-full flex justify-center mt-6">
                                            <Button onClick={sendRequest}  type='button' >Request</Button>
                                        </div>
                                    }

                                </div>
                                :
                                <div>
                                    {signedIn ? 
                                        
                                        <div className="w-full flex justify-center mt-6">
                                            <Button disabled={isdisabled} type='submit' >Continue</Button>
                                        </div>:
                                        <div>
                                            <h2 className="text-base font-semibold leading-7 text-gray-900">Cost for Guest: </h2>
                                            <p className=" text-sm leading-6 text-gray-600">$ {totalGuest} </p>
                                            
                                            <div className="w-full flex justify-center mt-6">
                                                <Button type='button' onClick={handleLogin} >LogIn / SignUp</Button>
                                            </div>

                                            <div className="mt-3 w-full flex justify-between items-center">
                                                <hr className="w-2/5" />
                                                <span className="uppercase">OR</span>
                                                <hr className="w-2/5" />
                                            </div>

                                            {(emailView)?
                                                <div>
                                                    <div className="mb-2 block mt-6">
                                                        <Label htmlFor="packagePriceLadies" value='Kindly provide your email to continue (We will send you links to your tickets using this email)' />
                                                    </div>
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        id="email"
                                                        required
                                                        defaultValue={email? email: ''}
                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />
                                                    <div className="w-full flex justify-center mt-6">
                                                        <Button disabled={isdisabled} type='submit' >Continue as guest</Button>
                                                    </div>
                                                </div>: 
                                                <div className="w-full flex justify-center mt-6">
                                                    <Button disabled={isdisabled} onClick={handleContinueAsGuest} type='button' >Continue as guest</Button>
                                                </div>
                                            }
                                        </div>
                                        
                                        
                                    }

                                </div>
                            }

                            
                        </form>:null
                    }


                    {(currentSubView === 'payment')?
                        <div>
                            <PaypalCheckout callBackFree={saveFreeTransaction} callBack={saveTransaction}/>
                        </div>: null
                    }


                    {(currentSubView === 'tickets')?
                        <div>
                            <h2 className="text-sm font-semibold leading-7 text-green-900">The transation was successful ...</h2>
                            {(premium === 'yes')?
                                <div>
                                    <h4 className="text-sm font-semibold leading-7 text-red-900">We will send you a notification if the host approves your request ...</h4>
                                </div>: null
                            }
                            <Tickets eventId={eventId} eventName={eventName} tickets={tickets} />
                        </div>: null
                    }


                    {(currentSubView ==='success')? 
                        <AlertComponent 
                            callBack={() => hideModal()} 
                            type='success' 
                            subject='Success' 
                            message={message}
                        />: null
                    } 

                    {(currentSubView ==='error')? 
                        <AlertComponent 
                            callBack={() => {setCurrentSubView('edit')}} 
                            type='error' 
                            subject='Failed' 
                            message={message}
                        />: null
                    } 
                    
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default GetEventPackageModal