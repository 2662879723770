import { useState, useEffect } from 'react';
import { useContextState } from '../context/Context';
import { useNavigate } from "react-router-dom";
import SecondHeader from '../components/headers/SecondHeader'
import PeopleList from '../components/social/PeopleList';
import LoginModal from '../components/modals/LoginModal'
import Footer from '../components/footers/Footer'
import ApplicationBar from '../components/shared/ApplicationBar';
import { useNotificationApi } from '../store';
import { useSocialApi } from '../store'
import backgroundImg from '../backgroundImg.jpeg'

const Friends = () => {
  const { accountCTX, socialCTX, eventsCTX } = useContextState();
  const { signedIn } = accountCTX
  const { setEventPageType } = eventsCTX;

  const { getPrivateAndEventsInboxUnreadMessagesCount } = useNotificationApi()

  const navigate = useNavigate();

  document.title = `FestiShare: My Social Dashboard`

  const {  
    getProfile, 
    getAccountFriends, 
  } = useSocialApi();

  const [isInitialLoadPotentialFriends, setIsInitialLoadPotentialFriends] = useState(true);
  const [isInitialLoadFriends, setIsInitialLoadFriends] = useState(true);
  const [isInitialLoadRequestsReceived, setIsInitialLoadRequestsReceived] = useState(true);
  const [isInitialLoadRequestsSent, setIsInitialLoadRequestsSent] = useState(true);

  const [resultIndexPotentialFriends, setResultIndexPotentialFriends] = useState<number>(1);
  const [resultIndexFriends, setResultIndexFriends] = useState<number>(1);
  const [resultIndexRequestsReceived, setResultIndexRequestsReceived] = useState<number>(1);
  const [resultIndexRequestsSent, setResultIndexRequestsSent] = useState<number>(1);

  const [hasMorePotentialFriends, setHasMorePotentialFriends] = useState<boolean>(true);
  const [hasMoreFriends, setHasMoreFriends] = useState<boolean>(true); 
  const [hasMoreRequestsReceived, setHasMoreRequestsReceived] = useState<boolean>(true);  
  const [hasMoreRequestsSent, setHasMoreRequestsSent] = useState<boolean>(true); 

  const {
    peopleState: { socialState, friendsState, requestsState, requestsSentState, friendsDiscoveryState },
    peopleFilterState : { sort, byRating, searchQuery, statusQuery } ,
    dispatchPeopleState
  } = socialCTX;

  const stateInfo = {
    'potentialFriends': [friendsDiscoveryState, isInitialLoadPotentialFriends, setIsInitialLoadPotentialFriends, resultIndexPotentialFriends, setResultIndexPotentialFriends, hasMorePotentialFriends, setHasMorePotentialFriends],
    'friends': [friendsState, isInitialLoadFriends, setIsInitialLoadFriends, resultIndexFriends, setResultIndexFriends, hasMoreFriends, setHasMoreFriends],
    'requestsReceived': [requestsState, isInitialLoadRequestsReceived, setIsInitialLoadRequestsReceived, resultIndexRequestsReceived, setResultIndexRequestsReceived, hasMoreRequestsReceived, setHasMoreRequestsReceived],
    'requestsSent': [requestsSentState, isInitialLoadRequestsSent, setIsInitialLoadRequestsSent, resultIndexRequestsSent, setResultIndexRequestsSent, hasMoreRequestsSent, setHasMoreRequestsSent]
  }

  var stateInfoValue = stateInfo[socialState as keyof typeof stateInfo ]
  var isInitialLoad = stateInfoValue[1]
  var resultIndex = stateInfoValue[3]
  var hasMore = stateInfoValue[5]

  const fetchPeople = async () => {
    const setResultIndex = stateInfoValue[4]
    const setHasMore = stateInfoValue[6]
    const setIsInitialLoad = stateInfoValue[2]

    const apiCall = await getAccountFriends({friendType:socialState, resultIndex:resultIndex})
    if (apiCall && (apiCall.results.length > 0)) {
      setResultIndex(apiCall.next ? resultIndex + 1 : resultIndex);
      setHasMore(apiCall.next? true: false);
      if (isInitialLoad){setIsInitialLoad(false);}
    }
    else {setHasMore(false)}
  };

  const loadMore = async () => {
    try {await fetchPeople();} 
    catch (error) { console.error('Error loading more events:', error);}
  };

  useEffect(()=>{
    setEventPageType('');
    dispatchPeopleState({ type: 'SET_SOCIAL_STATE', payload: 'friends' })
    // getPrivateInboxUnreadMessagesCount()
    // getEventsInboxUnreadMessagesCount()
    getPrivateAndEventsInboxUnreadMessagesCount()
  }, [])

  useEffect(() => {
    stateInfoValue = stateInfo[socialState as keyof typeof stateInfo ]
    isInitialLoad = stateInfoValue[1]
    hasMore = stateInfoValue[5]
    resultIndex = stateInfoValue[3]
    if (isInitialLoad){fetchPeople();}
  }, [ socialState ])
  
  return (
    <div>
      {!signedIn? 
        <main className="flex isolate min-h-full">
          <img
            src={backgroundImg}
            alt=""
            className="absolute inset-0 -z-10 h-full w-full object-cover object-top"
          />
          <div className='mt-50'>
            <LoginModal close={true} closeButton={() => navigate('/')} back={() => navigate('/')}/>
          </div>
        </main>
        :
        <div>
          <SecondHeader headerType='social' />
          <PeopleList 
            people= {stateInfo[socialState as keyof typeof stateInfo ][0]}
            loadMore= {loadMore}
            hasMore= {hasMore}
            socialState= {socialState}
          />
        </div>
      } 
      <Footer />
      <ApplicationBar />
    </div>
  )
    
    
}

export default Friends