import React, { useState } from 'react'
import { useContextState } from '../../context/Context';
import { useEventApi } from "../../store";
import { Share } from '../share';
import { Button, Checkbox, Label, Modal, TextInput } from 'flowbite-react';
import { FaRegShareFromSquare, FaDollarSign } from "react-icons/fa6";
import { ImCancelCircle } from "react-icons/im";
import config from '../../config';
import PaypalCheckout from '../paypal/PaypalCheckout';
import Loader from '../shared/Loader';
import AlertComponent from '../shared/AlertComponent';
import { IoIosArrowRoundBack } from 'react-icons/io';
import ValidateActionWithPassword from '../shared/ValidateActionWithPassword';


interface Props{
    eventId: string,
    eventName: string,
    tickets : any[];
}

const Tickets: React.FC<Props> = ({eventId, eventName, tickets}) => {
    var ticketsToList = tickets

    const { generatePendingTransactionCode, createIncomingPaypalTransaction, cancelTicket } = useEventApi();
    const { accountCTX } = useContextState();
    const { signedIn } = accountCTX

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const [currentSubView, setCurrentSubView] = useState<string>('default');
    const [pendingTransactionId, setPendingTransactionId ] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const [ticketIdValue, setTicketIdValue] = useState<string>('');

    const generatePendingTransaction = async (ticketId:string) => {
        setCurrentSubView('loader')
        setTicketIdValue(ticketId)

        const data = {
            transactionType: 'approvedRequestPayment', 
            id: ticketId,
        }
        
        const apiCall = await generatePendingTransactionCode(data);
                
        if (apiCall && apiCall.res === 'success'){
            setPendingTransactionId(apiCall.refId)
            setCurrentSubView('payment')
        }else{setMessage(apiCall.message); setCurrentSubView('error')}
        
    };

    const saveTransaction = async (orderID: string) => {
        setCurrentSubView('loader')
        // console.log(eventPackage.id);
        const apiCall = await createIncomingPaypalTransaction({
                orderID: orderID,
                packageId: ticketIdValue,
                eventId: eventId,
                transactionType:'approvedRequestPayment',
                transactionId: pendingTransactionId
            }
        );
        if (apiCall && (apiCall.res === 'success')) {
            ticketsToList = apiCall.data
            // setMessage('The transaction was successful ...'); 
            setCurrentSubView('default')
        }else{setCurrentSubView('error')}
    };

    const cancelThisTicket = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setCurrentSubView('loader')

        // console.log(eventPackage.id);
        const apiCall = await cancelTicket({
            eventId: eventId,
            ticketId: ticketIdValue,
            password: e.currentTarget.password.value, 
        });

        if (apiCall && (apiCall.res === 'success')) {
            ticketsToList = apiCall.data
            setMessage('You successfully cancelled your ticket. Kindly allow up to 3 business days to receive your refund if any ...'); 
            setCurrentSubView('success')
        }
        else if(apiCall && (apiCall.res === 'failed')){setMessage(apiCall.message); setCurrentSubView('error')}
        else{setMessage("Sorry, an error occured ..."); setCurrentSubView('error')}
    };

    

    
    return (
        <div>
            <div className=" py-12 sm:py-16">
                {(currentSubView === 'loader')? <div><Loader /></div>: null }
                
                {(currentSubView === 'default')?
                    <div className="mx-auto max-w-7xl px-6 text-center lg:px-8">
                        <div className="mx-auto max-w-2xl">
                            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">Your Tickets</h2>
                            <p className="mt-4 text-sm leading-8 text-gray-400">
                                Save your FestiShare tickets or their links because the event's host reserves the right to deny you if you do not present your ticket
                            </p>
                        </div>
                        <ul role="list" className="mx-auto mt-15 grid max-w-2xl grid-cols-1 gap-6 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-8" >
                            {ticketsToList.map((ticket, index) => (
                                // <li key={index} className={((ticket.gender === 'M') && (ticket.status === 'active'))? "rounded-2xl bg-blue-800 px-8 py-10" : "rounded-2xl bg-pink-800 px-8 py-10"}>
                                <li key={index} className={"rounded-2xl px-8 py-10 " + 
                                    (((ticket.gender === 'M') && (ticket.status === 'active'))?"bg-blue-800":'') + 
                                    (((ticket.gender === 'F') && (ticket.status === 'active'))?"bg-pink-800":'') + 
                                    ((ticket.status === 'request')?"bg-yellow-400":'') + 
                                    ((ticket.status === 'cancelled')?"bg-red-800":'') +
                                    ((ticket.status === 'scanned')?"bg-amber-100":'') +
                                    ((ticket.status === 'requestApproved')? "bg-green-600":'')
                                }
                                >
                                    <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-white"># {index+1}</h3>
                                    <img className="mx-auto h-48 w-48  md:h-56 md:w-56" src={ticket.qrCode} alt="" />
                                    <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-white">Package Name: {ticket.package}</h3>
                                    <p className="text-sm leading-6 text-gray-400">Status: {ticket.status}</p>
                                    <p className="text-sm leading-6 text-gray-400">Gender: {ticket.gender}</p>
                                    
                                    <ul role="list" className="mt-6 flex justify-center gap-x-6">
                                        <li>
                                            <div className="text-gray-400 hover:text-gray-300">
                                                <Share shareData={{ title: "Share Ticket", text: `This is the link of your ticket to ${eventName} `, url: `${config.baseUrl}/tickets/${ticket.id}` }}>
                                                    <FaRegShareFromSquare  /> Share
                                                </Share>
                                            </div>
                                        </li>
                                        {(ticket.status === 'requestApproved')?
                                            <div>
                                                <Button onClick={()=>{ return generatePendingTransaction(ticket.id)}} color="gray" pill><FaDollarSign /> Pay</Button>
                                            </div>
                                            :
                                            <div>
                                                {((ticket.status === 'active') && signedIn)?
                                                    <Button onClick={()=>{ setTicketIdValue(ticket.id); setShowModal(true); return setCurrentSubView("cancel")}} gradientMonochrome="failure" pill><ImCancelCircle /> Cancel</Button>
                                                    :null
                                                }
                                                
                                            </div>
                                        }
                                    </ul>
                                </li>
                            ))}
                        </ul>
                        <div className='flex justify-center mt-6'>
                            <Share shareData={{ title: "Share Ticket", text: `This is the link of your tickets to ${eventName} `, url: `${config.baseUrl}/tickets/${tickets[0].id}` }}>
                                <FaRegShareFromSquare />  Share all tickets
                            </Share>
                        </div>
                    </div>:null
                }

                {(currentSubView === 'cancel')? 
                    <Modal show={showModal} size="3xl" onClose={() => setCurrentSubView('default')}>
                        <Modal.Header><IoIosArrowRoundBack className="h-6 w-12 text-gray-600 group-hover:text-indigo-600" onClick={() => {setCurrentSubView('default')}} />Cancel Ticket</Modal.Header>
                        <Modal.Body>
                            <ValidateActionWithPassword  
                                eventId={eventId}
                                ticketId={ticketIdValue}
                                onSuccess={()=>{setCurrentSubView('default'); return setShowModal(false)}} 
                                callBack={cancelTicket} 
                            />
                        </Modal.Body>
                    </Modal>:null 
                }

                {(currentSubView === 'payment')? <div className='border-gray-900/10  flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 sm:mx-auto sm:w-full sm:max-w-sm'> <PaypalCheckout callBack={saveTransaction}/> </div>: null }

                {(currentSubView ==='success')? 
                    <AlertComponent 
                        callBack={() => {setCurrentSubView('default')}} 
                        type='success' 
                        subject='Success' 
                        message={message}
                    />: null
                } 

                {(currentSubView ==='error')? 
                    <AlertComponent 
                        callBack={() => {setCurrentSubView('default')}} 
                        type='error' 
                        subject='Failed' 
                        message={message}
                    />: null
                } 
            </div>
        </div>
    )
}

export default Tickets