import { useApi } from "./api"
// import { EventDTO, EventImage, EventPromoter, EventTicketPackageDTO } from "../models"
import { useContextState } from '../context/Context';


export const useTransactionsApi = () => {
    const { $post } = useApi()

    const { eventsCTX, accountCTX } = useContextState();
    const { signedIn } = accountCTX
    const { dispatchAllEventsState } = eventsCTX


    const cashOut = async (credentials: {transactionType:string, recipient_type:string, modelId?:string, userId:string, recipient_wallet:string, password:string}) => {
        if (!signedIn){return}
        var data = await $post('createOutgoingPaypalTransaction/', credentials, true)
        if (data && (data.res === 'success' || data.res === 'failed')){
            if (credentials.transactionType === 'event' && data.res === 'success'){
                console.log('test')
                dispatchAllEventsState({ type: 'UPDATE_EVENT', payload: {event: {'id': credentials.modelId,'status':'completed'}} })
            }
            return data
        }
        return 
    }

    
    return {
        cashOut
    }

}