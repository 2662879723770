import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { useNotificationApi } from './store';
import { useContextState } from './context/Context';


var firebaseConfig = {
    apiKey: "AIzaSyBPFDMODtKljQnZsHjMow-coTb6Ou9y_74",
    authDomain: "party-express-tech.firebaseapp.com",
    projectId: "party-express-tech",
    storageBucket: "party-express-tech.appspot.com",
    messagingSenderId: "862801003440",
    appId: "1:862801003440:web:43d8d79d5530a519df0c75",
    measurementId: "G-4FJ9XD8KS7"
};


const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);


export const GetNewToken = (setTokenFound:any) => {
  
  const { accountCTX } = useContextState();
  const { signedIn, deviceId, setDeviceId } = accountCTX

  const { saveDeviceToken } = useNotificationApi()

  const saveToken = async(currentToken:string) => {
    const data = { device_id: currentToken }
    var apiCall = await saveDeviceToken(data)
    .then((result:any) => {
        if (result){console.log('Token saved')}else{console.log('Token not saved')}
    });
  }

  // console.log('before useEffect')

  

    if (!deviceId){
      // console.log('calling Firebase')
      return getToken(messaging, {vapidKey: 'BKK_4Kq-84uyNGpHzEBTyxO4UBWzu1WD6c3D0TbS0OUMNmTwCS4oDpSmnm8BOximiYr3PPypN3JbtUCsw7ffnbM'}).then((currentToken) => {
        if (currentToken) {
          // alert('base')
          console.log('current token for client: ', currentToken);
          setTokenFound(true);
          setDeviceId(currentToken)

          console.log("token newly set");
  
          sessionStorage.setItem("notification_request", "set");
    
          if (signedIn){
            saveToken(currentToken)
          }
    
          // Track the token -> client mapping, by sending to backend server
          // show on the UI that permission is secured
        } else {
          console.log('No registration token available. Request permission to generate one.');
          setTokenFound(false);
          // shows on the UI that permission is required 
        }
      }).catch((err:any) => {
        // console.log('An error occurred while retrieving token. ', err);
      });
  
    }
    else {
      console.log("token already set");
      return setTokenFound(true)
    }
  

}


export const OnMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});

