import React, { useState } from 'react'
import { useGlobalModalContext } from './GlobalModal';
import { useContextState } from '../../context/Context';
import { useSocialApi } from '../../store';
import { useAuth } from "../../store";
import  { Button, Modal, Textarea } from 'flowbite-react';
import { Profile } from '../../models';
import logo from '../../logo.png';
import AlertComponent from '../shared/AlertComponent';
import { IoIosArrowRoundBack } from 'react-icons/io';
import Loader from '../shared/Loader';
import ImagesUpload from '../images/ImagesUpload';
import LoginModal from './LoginModal';
import { Share } from '../share';
import { FaRegShareFromSquare } from 'react-icons/fa6';
import { BsQrCodeScan } from 'react-icons/bs';



const ProfileModal = () => {
    const { showModal, hideModal, store } = useGlobalModalContext();
    const { modalProps } = store || {};
    const { title, confirmBtn, profileType, profileId } = modalProps || {};

    // console.log(profileId)

    const { accountCTX : {signedIn} } = useContextState();
    const { 
        getProfile,
        updateProfile,
        updateProfilePicture,
        requestFriendship,
        decideFriendship,
        unfriend, 
        follow,
        unfollow
    } = useSocialApi();

    const { checkAccount, updateUsername } = useAuth();

    const [profileToView, setProfileToView] = useState<Profile>({ id:'', username:'', firstName:'', lastName:'', gender:'', profilePicture:'', summary: '', funFact:'', link:'', qrCode: '' })
    const [profileFetched, setProfileFetched] = useState<boolean>(false)
    const [friend, setFriend] = useState<string>('')
    const [following, setFollowing] = useState<string>('')
    const [friendRequest, setFriendRequest] = useState<string>('')
    const [friendRequestSent, setFriendRequestSent] = useState<string>('')

    const [currentSubView, setCurrentSubView] = useState<string>('loader');
    const [username, setUsername] = useState<string>('');
    const [validUsername, setValidUsername] = useState<boolean>(false);
    const [isdisabled, setdisability] = useState<boolean>(true);

    
    const getGuestProfile = async () =>{
        const apiCall = await getProfile({profileId: profileId}).then((result:any) => {
            if (result && result.res === 'success'){
                // console.log(result)
                setFollowing(result.data.following)
                setFriend(result.data.friend)
                setFriendRequest(result.data.request)
                setFriendRequestSent(result.data.requestSent)
                setProfileToView(result.data)
                setCurrentSubView('default')
            }
        });
    }


    const handleFriendRequest = async () =>{
        const apiCall = await requestFriendship({friendId: profileId}).then((result:any) => {
            if (result && result.res === 'success'){
                console.log(result)
                setFriendRequestSent(result.data.id)
            }
        });
    }

    const handleAcceptFriend = async () =>{ 
        const apiCall = await decideFriendship({id: friendRequest, status: 'accept'}).then((result:any) => {
            if (result && result.res === 'success'){
                setFriend(result.data.id)
                setFriendRequest('')
            }
        });
    }

    const handleDenyFriend = async () =>{ 
        const apiCall = await decideFriendship({id: friendRequest, status: 'deny'}).then((result:any) => {
            if (result && result.res === 'success'){
                setFriend('')
                setFriendRequest('')
            }
        });
    } 
    
    const handleUnfriend = async () =>{ 
        var friendshipId = friend
        if (friendshipId === ''){
            friendshipId = friendRequestSent
        }
        const apiCall = await unfriend({id: friendshipId}).then((result:any) => {
            console.log(result)
            if (result && result.res === 'success'){
                setFriend('')
                setFriendRequestSent('')
            }
        });
    } 

    const handleFollow = async () =>{
        const apiCall = await follow({followee: profileId}).then((result:any) => {
            if (result && result.res === 'success'){
                setFollowing(result.data.id)
            }
        });
    }

    const handleUnfollow = async () =>{
        const apiCall = await unfollow({id: following}).then((result:any) => {
            if (result && result.res === 'success'){
                setFollowing('')
            }
        });
    }


    
    // if (profileType && profileType === 'guest' && !profileFetched){
    //     getGuestProfile(); setProfileFetched(true)
    // }else if ((!profileType || profileType === 'self') && !profileFetched ){ setProfileToView(profile); setProfileFetched(true)}

    if (profileId==='self' && !signedIn && !profileFetched){
        setCurrentSubView('login'); setProfileFetched(true)
    }

    else if (!profileFetched){
        getGuestProfile(); setProfileFetched(true)
    }

    async function validateUsername(usernameVar:string) {
        const apiCall:any = await checkAccount({username: usernameVar});
        if (!apiCall) {setValidUsername(true); setdisability(false)}
        else {setValidUsername(false); setdisability(true)}
    }

    const handleUsername = (e: React.FormEvent<HTMLInputElement>) => {
        const usernameVar = e.currentTarget.value;
        setUsername(usernameVar);
        if (usernameVar.length > 3 && usernameVar.length < 16){
            validateUsername(usernameVar);
        }else {setValidUsername(false); setdisability(true);console.log('lol2')}
    }

    const handleUsernameSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setCurrentSubView('loader')
        // console.log(profileToView.userId)
        // console.log(e.currentTarget.username.value)
            
        const data = {
            id: profileToView.userId as string,
            username: e.currentTarget.username.value as string,
        }
        
        const apiCall = await updateUsername(data).then((result:any) => {
            // console.log(result)
            if (result && result.res === 'success'){setCurrentSubView('success'); setProfileFetched(false)}
            else{setCurrentSubView('error')}
        });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setCurrentSubView('loader')
            
        const data = {
            id: profileToView.id,
            summary: e.currentTarget.summary.value,
            funFact : e.currentTarget.funFact.value, 
        }
        
        const apiCall = await updateProfile(data).then((result:any) => {
            // console.log(result)
            if (result && result.res === 'success'){setCurrentSubView('success'); setProfileFetched(false)}
            else{setCurrentSubView('error')}
        });
    };

    // console.log(profileToView) 
    return (
        <div>
            <Modal show={true} size="3xl" onClose={() => hideModal()}>
                <Modal.Header>{(currentSubView !== 'default')?<IoIosArrowRoundBack className="h-6 w-12 text-gray-600 group-hover:text-indigo-600" onClick={() => {setCurrentSubView('default')}} />: 'Profile'}</Modal.Header>
                <Modal.Body>
                    {currentSubView==='login'? <LoginModal close={true} callBack={()=>{setCurrentSubView('loader'); return getGuestProfile(); }}/>:null}
                    {currentSubView === 'loader'?<Loader />:null}
                    {currentSubView === 'default'?
                        <section id="author" aria-labelledby="author-title" className="relative scroll-mt-14 pb-3 pt-8 sm:scroll-mt-32 sm:pb-16 sm:pt-10 lg:pt-16">
                            <div className="absolute inset-x-0 bottom-0 top-1/2 text-slate-900/10 [mask-image:linear-gradient(transparent,white)]">
                                <svg aria-hidden="true" className="absolute inset-0 h-full w-full">
                                    <defs>
                                        <pattern id=":S7:" width="128" height="128" patternUnits="userSpaceOnUse" x="50%" y="100%">
                                            <path d="M0 128V.5H128" fill="none" stroke="currentColor"></path>
                                        </pattern>
                                    </defs>
                                    <rect width="100%" height="100%" fill="url(#:S7:)"></rect>
                                </svg>
                            </div>
                            <div className="relative mx-auto max-w-5xl pt-16 sm:px-6">
                                <div className="bg-slate-50 pt-px sm:rounded-6xl">
                                    <div className="relative mx-auto -mt-16 h-44 w-44 overflow-hidden rounded-full bg-slate-200 md:float-right md:h-64 md:w-64 md:[shape-outside:circle(40%)] lg:mr-20 lg:h-72 lg:w-72">
                                        <img 
                                            alt="" 
                                            loading="lazy" 
                                            width="576" 
                                            height="576" 
                                            decoding="async" 
                                            data-nimg="1" 
                                            className="absolute inset-0 h-full w-full object-cover" 
                                            color='transparent'  
                                            sizes="(min-width: 1024px) 18rem, (min-width: 768px) 16rem, 11rem" 
                                            src={profileToView.profilePicture} 
                                        />
                                    </div>
                                    <div className="px-4 py-10 sm:px-10 sm:py-16 md:py-20 lg:px-20 lg:py-32">
                                        {signedIn && (profileId=== 'self')?
                                            <div className='mb-6'>
                                                <h2 className="inline-flex items-center rounded-full px-4 py-1 text-blue-600 ring-1 ring-inset ring-blue-600 mt-3 mb-1" id="author-title">
                                                    <span className="font-mono text-sm" aria-hidden="true">ID</span>
                                                    <span className="ml-3 h-3.5 w-px bg-blue-600/20"></span>
                                                    <span className="ml-3 text-base font-medium tracking-tight">{profileToView.id.slice(-6)}</span>
                                                </h2>
                                                
                                                <h2 className="inline-flex items-center rounded-full px-4 py-1 text-blue-600 ring-1 ring-inset ring-blue-600 mt-3 mb-1" id="author-title">
                                                    <span className="font-mono text-sm" aria-hidden="true">Bonus</span>
                                                    <span className="ml-3 h-3.5 w-px bg-blue-600/20"></span>
                                                    <span className="ml-3 text-base font-medium tracking-tight">$ {profileToView.bonusTotal}</span>
                                                </h2>
                                                
                                                <Button className='mb-1' onClick={()=>{return setCurrentSubView('picture')}} gradientDuoTone="tealToLime" outline pill>Update Picture</Button>
                                                <Button className='mb-1' onClick={()=>{return setCurrentSubView('edit')}} gradientDuoTone="cyanToBlue" outline pill>Edit Profile</Button>
                                            </div>:null
                                        }

                                        
                                        <h2 className="inline-flex items-center rounded-full px-4 py-1 text-blue-600 ring-1 ring-inset ring-blue-600 mt-3 mb-1" id="author-title">
                                            <span className="font-mono text-sm" aria-hidden="true">@</span>
                                            <span className="ml-3 h-3.5 w-px bg-blue-600/20"></span>
                                            <span className="ml-3 text-base font-medium tracking-tight">{profileToView.username}</span>
                                            {signedIn && (profileId=== 'self')?
                                                <div>
                                                    <span className="ml-3 h-3.5 w-px bg-blue-600/20"></span>
                                                    <span className="ml-3 text-base tracking-tight">
                                                        <a onClick={()=>{return setCurrentSubView('edit pseudo')}} href='#'>
                                                            Edit
                                                        </a>
                                                    </span>
                                                </div>
                                                :null
                                            }
                                        </h2>

                                        <Button className='mb-1' onClick={()=>{return setCurrentSubView('qrCode')}} gradientDuoTone="cyanToBlue" outline pill>
                                            <BsQrCodeScan className="h-5 w-5 mr-3" /> Qr Code
                                        </Button>

                                        <Share shareData={{ title: "Share Ticket", text: `This is the link to the profile of ${profileToView.username} `, url: `${profileToView.link}` }}>
                                            <FaRegShareFromSquare />  Share Profile
                                        </Share>

                                        

                                        <p className="mt-8 font-display text-highlight font-extrabold tracking-tight text-slate-900">
                                            <span className="block text-blue-600">{profileToView.firstName}&nbsp;{profileToView.lastName.charAt(0)}. </span> 
                                        </p>

                                        <p className="mt-8 text-lg tracking-tight text-slate-700">
                                            <b>Summary:</b>
                                        </p>
                                        <p className="mt-3 text-lg tracking-tight text-slate-700">
                                            {profileToView.summary? profileToView.summary: ''}
                                        </p>

                                        <p className="mt-8 text-lg tracking-tight text-slate-700">
                                            <b>Fun Fact:</b>
                                        </p>
                                        <p className="mt-3 text-lg tracking-tight text-slate-700">
                                            {profileToView.funFact? profileToView.funFact: ''}
                                        </p>
                                        {/* <p className="mt-8">
                                            <a className="inline-flex items-center text-base font-medium tracking-tight text-blue-600" href="#">
                                                <svg aria-hidden="true" viewBox="0 0 40 40" className="h-10 w-10 fill-current">
                                                    <path d="M13.817 33.753c12.579 0 19.459-10.422 19.459-19.458 0-.297 0-.592-.02-.884a13.913 13.913 0 0 0 3.411-3.543 13.65 13.65 0 0 1-3.928 1.077 6.864 6.864 0 0 0 3.007-3.784 13.707 13.707 0 0 1-4.342 1.66 6.845 6.845 0 0 0-11.655 6.239A19.417 19.417 0 0 1 5.654 7.915a6.843 6.843 0 0 0 2.117 9.128 6.786 6.786 0 0 1-3.104-.853v.086a6.842 6.842 0 0 0 5.487 6.704 6.825 6.825 0 0 1-3.088.116 6.847 6.847 0 0 0 6.39 4.75A13.721 13.721 0 0 1 3.334 30.68a19.36 19.36 0 0 0 10.483 3.066"></path>
                                                </svg>
                                                <span className="ml-4">Follow on Twitter</span>
                                            </a>
                                        </p> */}
                                    </div>
                                </div>
                            </div>
                        </section>:null
                    }

                    {currentSubView === 'edit'?
                        <form onSubmit={handleSubmit}>
                            <div className="space-y-6">
                                <div className="w-full flex justify-center ">
                                    <img className="flex justify-center shadow rounded-xl" src={logo} alt="Logo" width={63} />
                                </div>
                                <div className="sm:col-span-3 mt-6">
                                    <label htmlFor="description" className="block text-sm font-medium text-gray-900">
                                        Summary
                                    </label>
                                    <div className="mt-2">
                                        <Textarea
                                            name="summary"
                                            id="summary"
                                            placeholder="Enter a summary about you ..."
                                            rows={4}
                                            required
                                            defaultValue={profileToView.summary? profileToView.summary: ''}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                <div className="sm:col-span-3 mt-6">
                                    <label htmlFor="description" className="block text-sm font-medium text-gray-900">
                                        Fun fact about you
                                    </label>
                                    <div className="mt-2">
                                        <Textarea
                                            name="funFact"
                                            id="funFact"
                                            placeholder="Enter fun facts about you ..."
                                            rows={4}
                                            required
                                            defaultValue={profileToView.funFact? profileToView.funFact: ''}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="w-full flex justify-center mt-6">
                                <Button type='submit' >Save</Button>
                            </div>
                        </form>:null
                    }

                    {currentSubView === 'edit pseudo'?
                        <form onSubmit={handleUsernameSubmit}>
                            <div className="sm:col-span-3">
                                <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                    Choose a username (between 4 and 15 characters)
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="username"
                                        id="username"
                                        minLength={4}
                                        maxLength={15}
                                        autoComplete="username"
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        value={username}
                                        onChange={handleUsername} 
                                    />
                                    <div className='flex justify-center'>
                                        { validUsername? 
                                            <h6 className='text-green-900'>Username available</h6>:<h6 className='text-red-900'>Username not available</h6>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="w-full flex justify-center mt-6">
                                <Button type='submit' disabled={isdisabled} >Save</Button>
                            </div>
                        </form>:null
                    }

                    {currentSubView === 'qrCode'?
                        <form onSubmit={handleSubmit}>
                            <div className="space-y-6">
                                <div className="w-full flex justify-center ">
                                    <img className="flex shadow rounded-xl" src={profileToView.profilePicture} alt="Profile Picture" width={126} />
                                </div>

                                <div className="w-full flex justify-center ">
                                    <p className="mt-2 font-display text-xl font-extrabold tracking-tight text-slate-900 sm:text-6xl">
                                        <span className="block text-blue-600">{profileToView.firstName}&nbsp;{profileToView.lastName.charAt(0)}. </span> 
                                    </p>
                                </div>

                                <div className="w-full flex justify-center ">
                                    <label className="block text-sm font-medium text-gray-900">
                                        Scan Me :)
                                    </label>
                                </div>

                                <div className="w-full flex justify-center ">
                                    <img className="flex justify-center shadow rounded-xl" src={profileToView.qrCode}  alt='Qr Code' width={256}/>
                                </div>
                                
                            </div>

                        </form>:null
                    }

                    {currentSubView === 'picture'?
                        <div className="space-y-6">
                            <ImagesUpload 
                                id={profileToView.id} 
                                apiFunction={updateProfilePicture} 
                                callBack={()=>{setCurrentSubView('default'); setProfileFetched(false); return  }} 
                                maxNumberOfFiles={1} />
                        </div>:null
                    }

                    {(currentSubView ==='success')? 
                        <AlertComponent 
                            callBack={() => { return setCurrentSubView('default')}} 
                            type='success' 
                            subject='Success' 
                            message='Congratulations, you have successfully updated your profile ...' 
                        />: null
                    } 

                    {(currentSubView ==='error')? 
                        <AlertComponent 
                            callBack={() => { return setCurrentSubView('default')}} 
                            type='error' 
                            subject='Failed' 
                            message='Sorry, there was an error ...' 
                        />: null
                    } 
                </Modal.Body>
                
                {signedIn && (profileType === 'guest')? 
                    <Modal.Footer>
                        <div className="flex flex-wrap gap-2">
                            {(following !== '')?
                                <Button onClick={()=>{return handleUnfollow()}} gradientMonochrome="failure">Unfollow</Button>
                                :
                                <Button onClick={()=>{return handleFollow()}} gradientMonochrome="info">Follow</Button>
                            }
                            
                            {friendRequest !== ''?
                                <div className="flex flex-wrap gap-2">
                                    <Button onClick={()=>{return handleAcceptFriend()}} gradientMonochrome="success">Accept Friendship</Button>
                                    <Button onClick={()=>{return handleDenyFriend()}} gradientMonochrome="failure" >Deny</Button>
                                </div>
                                :
                                <div>
                                    {friendRequestSent !== ''? 
                                        <div>
                                            <Button onClick={()=>{return handleUnfriend()}} gradientMonochrome="failure">Cancel Friend Request</Button>
                                        </div>:
                                        <div>
                                            {(friend !== '') ?
                                                <Button onClick={()=>{return handleUnfriend()}} gradientMonochrome="failure">Unfriend</Button>
                                                :
                                                <Button onClick={()=>{return handleFriendRequest()}} outline gradientDuoTone="greenToBlue" >Request Friendship</Button>
                                            }
                                        </div>
                                    }
                                    
                                </div>
                            }
                        </div>
                    </Modal.Footer>: null
                }
            </Modal>
        </div>
    )
}

export default ProfileModal