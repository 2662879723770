import { Button, Modal } from 'flowbite-react';
import React, { useState } from 'react'
import { DiAndroid } from "react-icons/di";
import { FaWindows, FaApple } from "react-icons/fa";
import { IoShareOutline } from "react-icons/io5";
import logo from '../../logo.png';
import Share from '../share/Share';
import config  from '../../config'

const Pwa = () => {
    const [deviceType, setDeviceType] = useState<string>('')
    const [deferredPrompt, setDeferredPrompt] = useState<any>('');
    const [installButton, setInstallButton] = useState<React.ReactNode>('');
    const [showInstallButton, setShowInstallButton] = useState<boolean>(false);
    const [showAppleInstall, setShowAppleInstall] = useState<boolean>(false);
    const [isLoaded, setIsLoaded] = useState<boolean>(false)

    const install_prompt = () => {
        // console.log('lol1');

        window.addEventListener("beforeinstallprompt", (e) => {
            // console.log('lol2');
            // Prevent Chrome 67 and earlier from automatically showing the prompt
            e.preventDefault();
            // Stash the event so it can be triggered later.
            setDeferredPrompt(e);
            // Update UI to notify the user they can add to home screen
            setShowInstallButton(true)
        });
    }

    const install_app = () => {
        // const addBtn = document.getElementById("install_button");
        // hide our user interface that shows our A2HS button
        // addBtn.style.display = "none";
        console.log('lol2');
        // Show the prompt
        deferredPrompt.prompt();
        // Wait for the user to respond to the prompt
        deferredPrompt.userChoice.then((choiceResult:any) => {
            if (choiceResult.outcome === "accepted") {
                console.log("User accepted the A2HS prompt");
            } else {
                console.log("User dismissed the A2HS prompt");
                if (/iPhone|Mac/i.test(navigator.userAgent)) {
                    // addBtn.style.display = "none";
                }
            }
            setDeferredPrompt(null);
        });
        
    }

    function detectDevice(){
        // console.log('yes');
        if (/Android/i.test(navigator.userAgent)) {
            setDeviceType('Android');
        }
  
        else if (/iPhone|Mac/i.test(navigator.userAgent)) {
            setDeviceType('Apple')
        }
      
        else if (/Win/i.test(navigator.userAgent)) {
            setDeviceType('Windows');
    
        }
    }

    const launch_app_install_prompt =() => {
        detectDevice();
        install_prompt();
    }

    if (!isLoaded){
        setIsLoaded(true)

        if ((typeof(Storage) !== "undefined") && (typeof localStorage.getItem("app") !== "undefined")) {
            if (localStorage.getItem("app") !== 'yes'){
                launch_app_install_prompt();
            }
        }
        else {
            launch_app_install_prompt();
        } 
    }

    


    return (
        <div className='fixed bottom-[150px] right-10  z-20'>
            {showInstallButton && deviceType !== 'Apple'? 
                <Button
                    onClick={()=> {return install_app()}}
                    type="button"
                    gradientDuoTone="purpleToBlue" 
                    pill
                >
                    {deviceType === 'Android'? <DiAndroid  size={'27px'}/>:null}
                    {deviceType === 'Windows'? <FaWindows  size={'27px'}/>:null}
                    Install FestiShare
                </Button>:null
            }
            {showInstallButton && deviceType === 'Apple'? 
                <Button
                    onClick={()=> {return setShowAppleInstall(true)}}
                    type="button"
                    gradientDuoTone="purpleToBlue" 
                    pill
                >   
                    <FaApple  size={'27px'}/>
                    Install FestiShare
                </Button>:null
            }

            <Modal show={showAppleInstall} position="bottom-center" onClose={() => setShowAppleInstall(false)}>
                <Modal.Header> 
                    <div className='flex flex-row items-center'>
                        <img src={logo} width="27" height="27" className="border-radius:6px;"  alt="FestiShare logo" /> 
                        <div className='flex items-center'>Installation Instructions</div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="space-y-6 p-6">
                        <p className="card-title"><b>Install FestiShare</b> on your device to easily access it anytime. No App Store. No Download. No hassles. Just two steps</p>
                        <div className='flex flex-col'>
                            <p>1. Tap on Share</p>
                            <center>
                                <Share shareData={{ title: "Install FestiShare", text: `Click on 'Add to Home Screen' to install`, url: `${config.baseUrl}` }}>
                                    <IoShareOutline size={'27px'} />
                                </Share>
                            </center>
                        </div>

                        <div className='flex flex-col'>
                            <p>2. Then Select </p>
                            <center><Button color="gray" outline disabled> Add to Home Screen</Button></center>
                        </div>
                        
                                
                                
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button color="gray" onClick={() => setShowAppleInstall(false)}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
            
        </div>
    )
}

export default Pwa