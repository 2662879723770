import React, { useState, createContext, useContext } from "react";
import LoginModal from "./LoginModal";
import ProfileModal from "./ProfileModal";
import Create_Event_Modal from "./CreateEventModal";
import EditEventPackageModal from "./EditEventPackageModal";
import GetEventPackageModal from "./getEventPackageModal";
import EditEventPromoterModal from "./EditEventPromoterModal";
import DeleteAccountModal from "./deleteAccountModal";
//import { CreateModal, DeleteModal, UpdateModal } from "../../components";

export const MODAL_TYPES = {
    LOGIN_MODAL: "LOGIN_MODAL",
    PROFILE_MODAL: "PROFILE_MODAL",
    CREATE_EVENT_MODAL: "CREATE_EVENT_MODAL",
    EDIT_EVENT_PACKAGE_MODAL: "EDIT_EVENT_PACKAGE_MODAL",
    GET_EVENT_PACKAGE_MODAL: "GET_EVENT_PACKAGE_MODAL",
    EDIT_EVENT_PROMOTER_MODAL: "EDIT_EVENT_PROMOTER_MODAL",
    DELETE_ACCOUNT_MODAL: "DELETE_ACCOUNT_MODAL",
};

const MODAL_COMPONENTS: any = {
  [MODAL_TYPES.LOGIN_MODAL]: LoginModal,
  [MODAL_TYPES.PROFILE_MODAL]: ProfileModal,
  [MODAL_TYPES.CREATE_EVENT_MODAL]: Create_Event_Modal,
  [MODAL_TYPES.EDIT_EVENT_PACKAGE_MODAL]: EditEventPackageModal,
  [MODAL_TYPES.GET_EVENT_PACKAGE_MODAL]: GetEventPackageModal,
  [MODAL_TYPES.EDIT_EVENT_PROMOTER_MODAL]: EditEventPromoterModal,
  [MODAL_TYPES.DELETE_ACCOUNT_MODAL]: DeleteAccountModal,
};

interface Store {
    modalType: string | null,
    modalProps?: any
}

interface ModalContext  {
  showModal: Store | any;
  hideModal: () => void;
  store: any;
};


type ContextProps = {
    children:React.ReactNode;
}

// const initalState: ContextProps = {
//   showModal: () => {},
//   hideModal: () => {},
//   store: {},
//   children: {}
// };


const GlobalModalContext = createContext<ModalContext | null>(null);

export const GlobalModal= ({ children }: ContextProps) => {
    // debugger;
    const [store, setStore] = useState<Store>();
    const { modalType, modalProps } = store || {};

    const showModal = (modalType: string, modalProps: any = {}) => {
        setStore({
            ...store,
            modalType,
            modalProps
        });
    };

    const hideModal = () => {
        setStore({
            ...store,
            modalType: null,
            modalProps: {}
        });
    };

    const renderComponent = () => {
        if (modalType && modalType !== null ){
            const ModalComponent = MODAL_COMPONENTS[modalType];
            // console.log(ModalComponent);
            if (!modalType || !ModalComponent) {
                return null;
            }
            return <ModalComponent id="global-modal" {...modalProps} />;
        }
        else {
            return null;
        }
    };

    return (
        <GlobalModalContext.Provider value={{ store, showModal, hideModal }}>
            {renderComponent()}
            {children}
        </GlobalModalContext.Provider>
    );
};


export const useGlobalModalContext =() => {
    const context = useContext(GlobalModalContext);
    if (!context){
        throw new Error(
            "useGlobalModalContext must be used within GlobalModalContext"
        )
    }

    // console.log(context)
    return context
}
