import { useState, useRef, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import SecondHeader from '../components/headers/SecondHeader';
import EventsListHome from '../components/events/EventsListHome';
import Footer from '../components/footers/Footer'
import ApplicationBar from '../components/shared/ApplicationBar';
import { useContextState } from '../context/Context';
import { useGlobalModalContext } from '../components/modals/GlobalModal';
import LoginModal from '../components/modals/LoginModal';
import backgroundImage from '../backgroundImage.jpeg'
import backgroundImg from '../backgroundImg.jpeg'

interface Props{
  isApp?: boolean;
}

const Home: React.FC<Props> = ({isApp}) => {
  const { hideModal } = useGlobalModalContext();
  const { accountCTX } = useContextState();
  const { signedIn } = accountCTX

  const [openModal, setOpenModal] = useState<string | undefined>();
  const emailInputRef = useRef<HTMLInputElement>(null)
  const props = { openModal, setOpenModal, emailInputRef };

  var pageLocation = window.location.pathname;
  if (pageLocation==='/app' || isApp){
    localStorage.setItem("app", 'yes');
  }
  else if (pageLocation==='/web' || isApp){
    localStorage.setItem("app", 'no');
  }

  const navigate = useNavigate();

  document.title = `FestiShare: Discover and Host Exciting Events Near You!`

  
  if (isApp){
    return (
      <div >
        {!signedIn? 
          <main className="flex isolate min-h-full">
            <img
              src={backgroundImg}
              alt=""
              className="absolute inset-0 -z-10 h-full w-full object-cover object-top"
            />
            <div className='mt-50'>
              <LoginModal close={true} closeButton={() => navigate('/')} back={() => navigate('/')}/>
            </div>
          </main>
          :
          <div>
            <SecondHeader />
            <EventsListHome />
            <Footer />
            <ApplicationBar />
          </div>
        }
      </div>
    )
  }
  else {
    return (
      <div >
          <SecondHeader />
          <EventsListHome />
          <Footer />
          <ApplicationBar />
      </div>
    )
  }

  
}

export default Home