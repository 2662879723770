import { Fragment, useEffect, useState } from 'react'
import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon,
  MapPinIcon,
} from '@heroicons/react/20/solid'
import { Menu, Transition } from '@headlessui/react'
import { useGlobalModalContext, MODAL_TYPES } from '../modals/GlobalModal'
import { useAccountApi, useShared } from '../../store'
import AlertComponent from '../shared/AlertComponent'
import { Modal } from 'flowbite-react'
import EventDetails from '../events/eventDetails'


function classNames(...classes:any[]) {
  return classes.filter(Boolean).join(' ')
}

const Calendar = () => {
    const { showModal } = useGlobalModalContext();
    const [openModal, setOpenModal] = useState<string | undefined>();
    const props = { openModal, setOpenModal,  };
    
    const { getMyCalendar } = useAccountApi();
    const { titleCase } = useShared();

    const [currentSubView, setCurrentSubView] = useState<string>('loader');
    // const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const [events, setEvents] = useState<any[]>()
    const [days, setDays ] = useState<any[]>()
    const [thisDay, setThisDay] = useState<any>()
    const [thisMonthDescription, setThisMonthDescription] = useState<string>()
    // const [thisMonth, setThisMonth] = useState<any>(today.getMonth() + 1)
    // const [thisYear, setThisYear] = useState<any>(today.getFullYear())

    const [showNewModal, setShowNewModal] = useState<boolean>(false);
    const [modalType, setModalType] = useState<string>('');
    const [eventId, setEventId] = useState<string>('');


    const eventModal = (eventId:string) =>{
        setEventId(eventId);
        setModalType('Event')
        setShowNewModal(true)
    }

    const getCalendar = (year: string, month:string) => {
        getMyCalendar({year:year, month:month}).then(apiCall => { 
            if (apiCall.res && apiCall.res==='success'){ 
                setEvents(apiCall.data.events);
                setDays(apiCall.data.days);
                setCurrentSubView('default')
            }
            else if (apiCall.res && apiCall.res==='failed') { 
                setEvents([]);
                setDays([]);
                setMessage(apiCall.message)
                setCurrentSubView('error')
            }
            else {
                setEvents([]);
                setDays([]);
                setMessage('Sorry, there was an error')
                setCurrentSubView('error')
            }

        });
    }

    const getPreviousMonth = () => {
        var newDate = new Date(thisDay.setMonth(thisDay.getMonth()-1));
        setThisDay(newDate)
    }

    const getNextMonth = () => {
        var newDate = new Date(thisDay.setMonth(thisDay.getMonth()+1));
        setThisDay(newDate)
    }


    useEffect(()=>{
        const today = new Date()
        setThisDay(today)
    }, [])


    useEffect(()=>{
        // console.log('started use Eff')
        if (thisDay){
            const options = { month: "long" } as const;
            // const newDate = thisYear.toString() + '-' + (thisMonth).toString() + '-15'
            // console.log(thisYear.toString(), (thisMonth).toString())
            const newMonthDescription = (new Intl.DateTimeFormat("en-US", options).format(thisDay)) + ' ' + thisDay.getFullYear().toString()
            setThisMonthDescription(newMonthDescription)
            getCalendar(thisDay.getFullYear().toString(), (thisDay.getMonth()+1).toString())
        }
        // console.log('finished use eff')

    }, [thisDay])

    return (
        <div className='mx-auto max-w-7xl px-6 lg:px-8'>
            <h2 className="text-base font-semibold leading-6 text-gray-900">My Events Calendar</h2>
            <div className="lg:grid lg:grid-cols-12 lg:gap-x-16 mt-28">
                <div className="mt-10 text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 lg:mt-9 xl:col-start-9">
                    <div className="flex items-center text-gray-900">
                        <button
                            type="button"
                            className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                            onClick={()=>{return getPreviousMonth()}}
                        >
                            <span className="sr-only">Previous month</span>
                            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                        <div className="flex-auto text-sm font-semibold">{thisMonthDescription}</div>
                        <button
                            type="button"
                            className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                            onClick={()=>{return getNextMonth()}}
                        >
                            <span className="sr-only">Next month</span>
                            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
                        <div>M</div>
                        <div>T</div>
                        <div>W</div>
                        <div>T</div>
                        <div>F</div>
                        <div>S</div>
                        <div>S</div>
                    </div>
                    <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 text-sm shadow ring-1 ring-gray-200">
                        {days?.map((day, dayIdx) => (
                            <button
                                key={dayIdx}
                                type="button"
                                className={classNames(
                                    'py-1.5 hover:bg-gray-100 focus:z-10',
                                    day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
                                    (day.isSelected || day.isToday) && 'font-semibold',
                                    day.isSelected && 'text-white',
                                    !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900',
                                    !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-400',
                                    day.isToday && !day.isSelected && 'text-indigo-600',
                                    dayIdx === 0 && 'rounded-tl-lg',
                                    dayIdx === 6 && 'rounded-tr-lg',
                                    dayIdx === days.length - 7 && 'rounded-bl-lg',
                                    dayIdx === days.length - 1 && 'rounded-br-lg'
                                )}
                            >
                                <time
                                    dateTime={day.date}
                                    className={classNames(
                                        'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                                        day.isSelected && day.isToday && 'bg-indigo-600',
                                        day.isSelected && !day.isToday && 'bg-gray-900'
                                    )}
                                >
                                    {day?.date?.split('-')?.pop()?.replace(/^0/, '')}
                                </time>
                            </button>
                        ))}
                    </div>
                    {/* <button
                        type="button"
                        className="mt-8 w-full rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        Add event
                    </button> */}
                </div>

                <ol className="mt-4 divide-y divide-gray-100 text-sm leading-6 lg:col-span-7 xl:col-span-8">
                    {events?.map((event) => (
                        <li key={event.id} className="relative flex space-x-6 py-6 xl:static">
                            <img src={event.icon} alt="" className="h-14 w-14 flex-none rounded-full" />
                            <div className="flex-auto">
                                <h3 className="pr-10 font-semibold text-gray-900 xl:pr-0">{event.name}</h3>
                                <dl className="mt-2 flex flex-col text-gray-500 xl:flex-row">
                                <div className="flex items-start space-x-3">
                                    <dt className="mt-0.5">
                                    <span className="sr-only">Date</span>
                                    <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </dt>
                                    <dd>
                                    <time dateTime={event.startDate}>
                                        {event.startDate.toString()} at {event.startTime.toString()}&nbsp; <b>To</b>&nbsp;{event.endDate.toString()} at {event.endTime.toString()}
                                    </time>
                                    </dd>
                                </div>
                                <div className="mt-2 flex items-start space-x-3 xl:ml-3.5 xl:mt-0 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                                    <dt className="mt-0.5">
                                    <span className="sr-only">Location</span>
                                    <MapPinIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </dt>
                                    <dd>{titleCase(event.address)}, {titleCase(event.city)}, {titleCase(event.state)} {event.postcode}, {titleCase(event.country)}</dd>
                                </div>
                                </dl>
                            </div>
                            <Menu as="div" className="absolute right-0 top-6 xl:relative xl:right-auto xl:top-auto xl:self-center">
                                <div>
                                    <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-gray-500 hover:text-gray-600">
                                        <span className="sr-only">Open options</span>
                                        <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
                                    </Menu.Button>
                                </div>

                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="absolute right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="py-1">
                                            <Menu.Item>
                                                {({ active }) => (
                                                <a  
                                                    onClick={()=>{return eventModal(event.id)}}
                                                    href="#"
                                                    className={classNames(
                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'block px-4 py-2 text-sm'
                                                    )}
                                                >
                                                    See Event
                                                </a>
                                                )}
                                            </Menu.Item>
                                            {/* <Menu.Item>
                                                {({ active }) => (
                                                <a
                                                    href="#"
                                                    className={classNames(
                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'block px-4 py-2 text-sm'
                                                    )}
                                                >
                                                    Cancel
                                                </a>
                                                )}
                                            </Menu.Item> */}
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </li>
                    ))}
                </ol>
            </div>

            {(currentSubView ==='error')? 
                <AlertComponent 
                    callBack={() => {setCurrentSubView('default')}} 
                    type='error' 
                    subject='Failed' 
                    message={message}
                />: null
            } 


            <Modal show={showNewModal} size="3xl" onClose={() => setShowNewModal(false)}>
                <Modal.Header>{modalType}</Modal.Header>
                <Modal.Body>
                    {(modalType === 'Event')?
                        <EventDetails eventId={eventId} />:null
                    }
                    
                </Modal.Body>
            </Modal>
        </div>
    )
}


export default Calendar
