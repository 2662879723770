import { useState, useEffect } from 'react'
import { useContextState } from '../../context/Context'
import { useEventApi } from "../../store";
import { useShared } from '../../store';
import { Event } from '../../models';
import EventsList from '../events/EventsList'


interface Props{
  purpose?: string;
  eventType?: string;
  eventCallBack? : (event:Event) => void;
}
  
const EventsListHome: React.FC<Props> = ({purpose, eventType, eventCallBack}) => {

  const { eventsCTX } = useContextState();
  const {
    setEventPageType,
    allEventsState: { events },
    eventsState: { sort, byStock, byFastDelivery, byRating, searchQuery, lastFilterChange, statusQuery, ticketStatusQuery, ticketAndEventStatusQuery, ticketOrEventStatusQuery },
  } = eventsCTX;

  const { 
    getEvents
  } = useEventApi();

  const {  
    dateDifferenceSign,
    getUniqueElementsOfArray,
    isThisAPastTime
  } = useShared();


  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [currentPageView, setCurrentPageView] = useState<string>('loader');
  const [pageLocation, setPageLocation] = useState<string>(window.location.pathname);
  const [resultIndex, setResultIndex] = useState<number>(1);
  const [sortedEvents, setSortedEvents] = useState<Event[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true); 

  const fetchEvents = async () => {
    if (currentPageView!=='loader'){setCurrentPageView('default')}

    const apiCall = await getEvents(resultIndex)
    if (apiCall && (apiCall.results.length > 0)) {
      setResultIndex(apiCall.next ? resultIndex + 1 : resultIndex);
      setHasMore(apiCall.next? true: false);
      if (isInitialLoad){setIsInitialLoad(false);}
    }
    else {setHasMore(false)}
    if (currentPageView==='loader'){setCurrentPageView('default')}
  };
  
  const transformEvents = (eventsToList:Event[]) => {
      
    let eventsToBeSorted = eventsToList;

    if (sort) {
      eventsToBeSorted = eventsToBeSorted.sort((a:any, b:any) =>
        sort === "soonToLater" ? dateDifferenceSign(a.startDate, b.startDate) : dateDifferenceSign(b.startDate, a.startDate)
      );
    }

    if (byStock) {
      eventsToBeSorted = eventsToBeSorted.filter((event:any) => event.inStock);
    }

    if (byFastDelivery) {
      eventsToBeSorted = eventsToBeSorted.filter((event:any) => event.fastDelivery);
    }

    if (byRating) {
      eventsToBeSorted = eventsToBeSorted.filter(
        (event:any) => event.ratings >= byRating
      );
    }

    if (searchQuery && (pageLocation!=='/mytickets') && (pageLocation!=='/myevents')) {
      // console.log('status: ', searchQuery)
        eventsToBeSorted = eventsToBeSorted.filter((event:any) => 
            event.type.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }

    if (statusQuery && (pageLocation!=='/mytickets') && (pageLocation!=='/')) {
      // console.log(statusQuery)
      eventsToBeSorted = eventsToBeSorted.filter((event:any) =>{ 
        if (statusQuery === 'upcoming'){
          // console.log(event.endDate , (event.status.toLowerCase().includes(statusQuery)) && (!isThisAPastTime(event.endDate)))
          return (event.status.toLowerCase().includes(statusQuery)) && (!isThisAPastTime(event.endDate))
        }
        else if (statusQuery === 'completed'){
          return (event.status.toLowerCase().includes(statusQuery)) || (event.status.toLowerCase().includes('upcoming') && isThisAPastTime(event.endDate))
        }
        return event.status.toLowerCase().includes(statusQuery)
      });
    }

    if (ticketAndEventStatusQuery) {
      // console.log(ticketAndEventStatusQuery)
      eventsToBeSorted = eventsToBeSorted.filter((event:any) => {
        if (event.tickets && event.tickets[0] ){
          const eventStatus = ticketAndEventStatusQuery.event
          const ticketStatus = ticketAndEventStatusQuery.ticket
          const operator = ticketAndEventStatusQuery.operator
          if ((ticketStatus !== '') && (eventStatus !== '') ){
            if (operator === 'AND'){
              const queryValidator = (event.status.toLowerCase().includes(eventStatus.toLowerCase()) && event.tickets[0].status.toLowerCase().includes(ticketStatus.toLowerCase()))
              if (eventStatus === 'upcoming' && ticketStatus === 'active'){
                return (queryValidator && (!isThisAPastTime(event.endDate)))
              }
              return queryValidator
            }
            else {
              const queryValidator = (event.status.toLowerCase().includes(eventStatus.toLowerCase()) || event.tickets[0].status.toLowerCase().includes(ticketStatus.toLowerCase()))
              if (eventStatus === 'completed' && ticketStatus === 'scanned'){
                return (queryValidator || (isThisAPastTime(event.endDate)))
              }
              return queryValidator
            }
          }
          else if(ticketStatus !== ''){
            return event.tickets[0].status.toLowerCase().includes(ticketStatus.toLowerCase())
          }
          else if (eventStatus !== ''){
            return event.status.toLowerCase().includes(eventStatus.toLowerCase())
          }
        }
        return true
      });
    }

    //return unique events (without duplicates)
    const uniqueEvents = getUniqueElementsOfArray(eventsToBeSorted)
    return uniqueEvents;
  };

  useEffect(() => {
    setEventPageType('');
    fetchEvents();
    setIsInitialLoad(false);
  }, []);

  useEffect(() => {
    var eventsToShow = transformEvents(events)
    setSortedEvents(eventsToShow)
  }, [events, sort, byStock, byFastDelivery, byRating, searchQuery, statusQuery, ticketStatusQuery, ticketAndEventStatusQuery, ticketOrEventStatusQuery])

  const loadMore = async () => {
    try {
      await fetchEvents();
    } catch (error) {
      console.error('Error loading more events:', error);
      // Handle errors appropriately
    }
  };

  return (
    <EventsList 
      events={sortedEvents}
      loadMore={loadMore}
      hasMore={hasMore}
      purpose={purpose}
      eventType={eventType}
      eventCallBack={eventCallBack} 
    />
  )
}


export default EventsListHome
  