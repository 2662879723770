import { useApi } from "./api"
// import { EventDTO, EventImage, EventPromoter, EventTicketPackageDTO } from "../models"
import { useContextState } from '../context/Context';


export const useMessagesApi = () => {
    const { $post, $get } = useApi()

    const { inboxCTX, accountCTX } = useContextState();
    const { signedIn } = accountCTX
    const { dispatchInboxState } = inboxCTX


    const getInboxes = async (credentials: {type:string}) => {
        if (!signedIn){return}
        var data = await $get('messages/inbox/', true, credentials)
        // console.log(data.data)
        if (data && (data.res === 'success' || data.res === 'failed')){
            if (credentials.type === 'event' && data.res === 'success'){
                dispatchInboxState({ type: 'SET_EVENTS_INBOX_STATE', payload: data.data})
            }
            else if (credentials.type === 'private' && data.res === 'success'){
                dispatchInboxState({ type: 'SET_PRIVATE_INBOX_STATE', payload: data.data })
            }
            return data
        }
        return 
    }

    const getMessages = async (credentials: {id:string}) => {
        if (!signedIn){return}
        const path = "messages/inbox/" + credentials.id + "/"
        var data = await $get(path, true)
        // console.log(data.data)
        if (data && (data.res === 'success' || data.res === 'failed')){
            return data
        }
        return 
    }

    const sendMessage = async (credentials: any) => {
        if (!signedIn){return}
        const path = "messages/"
        var data = await $post(path, credentials, true, false)
        // console.log(data.data)
        if (data && (data.res === 'success' || data.res === 'failed')){
            return data
        }
        return 
    }

    const sendSupportMessage = async (credentials: {subject:string, message:string, type:string}) => {
        if (!signedIn){return}
        const path = "inbox/"
        var data = await $post(path, credentials, true, false)
        // console.log(data.data)
        if (data && (data.res === 'success' || data.res === 'failed')){
            return data
        }
        return 
    }

    return {
        getInboxes,
        getMessages,
        sendMessage,
        sendSupportMessage
    }
}