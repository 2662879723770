import { useApi } from "./api"
import { useContextState } from '../context/Context';



export const useSocialApi = () => {
    const { $post, $put, $patch, $delete } = useApi()

    const { socialCTX, accountCTX } = useContextState();
    const { signedIn } = accountCTX
    const { dispatchPeopleState } = socialCTX


    const getProfile = async (credentials: {profileId:string}) => {
        var data = await $post('account/action/get_profile/', credentials, (credentials.profileId === 'self')?true:false)
        if (data && (data.res === 'success' || data.res === 'failed')){
            return data
        }
        return 
    }

    const updateProfile = async (credentials: {id:string, summary?:string, funFact?:string}) => {
        const path = 'account/profile/' + credentials.id + '/'
        var data = await $patch(path, credentials)
        return data
    }

    const updateProfilePicture = async (images: any) => {
        console.log(images)
        const path = 'account/profile/' + images.get('id') + '/'
        var data = await $patch(path, images)
        // console.log(data)
        if (!data) return
        return data
    }

    const getAccountFriends = async (credentials: {friendType:string, resultIndex:number}) => {
        var path = 'account/friendship/get_account_friends/?page=' + (credentials.resultIndex? credentials.resultIndex?.toString():'1')
        var data = await $post(path, credentials, true)
        // console.log(data)
        if (data && data.count >= 0){
            const actionTypeMap = {
                "potentialFriends": 'SET_FRIEND_DISCOVERY_STATE',
                "friends": 'SET_FRIENDS_STATE',
                "requestsReceived": 'SET_REQUEST_STATE',
                "requestsSent": 'SET_REQUEST_SENT_STATE',
            };

            // const isAppend = Number(credentials.resultIndex) > 1;
            const friendTypeAction = actionTypeMap[credentials.friendType as keyof typeof actionTypeMap];

            dispatchPeopleState({
                type: friendTypeAction,
                // payload: data.results,
                payload: data.results,
            });
            
            return data
        }
        return 
    }

    const requestFriendship = async (credentials: {friendId:string}) => {
        var data = await $post('account/friendship/', credentials, true)
        if (data && (data.res === 'success' || data.res === 'failed')){
            return data
        }
        return 
    }

    const decideFriendship = async (credentials: {id:string, status:string}) => {
        const path = 'account/friendship/' + credentials.id + '/'
        var data = await $put(path, credentials)
        if (data && (data.res === 'success' || data.res === 'failed')){
            return data
        }
        return 
    }

    const unfriend = async (credentials: {id:string}) => {
        const path = 'account/friendship/' + credentials.id + '/'
        var data = await $delete(path)
        if (data && (data.res === 'success' || data.res === 'failed')){
            return data
        }
        return 
    }

    const follow = async (credentials: {followee:string}) => {
        var data = await $post('account/followship/', credentials, true)
        if (data && (data.res === 'success' || data.res === 'failed')){
            return data
        }
        return 
    }

    const unfollow = async (credentials: {id:string}) => {
        const path = 'account/followship/' + credentials.id + '/'
        var data = await $delete(path)
        if (data && (data.res === 'success' || data.res === 'failed')){
            return data
        }
        return 
    }


    return {
        getProfile,
        updateProfile,
        updateProfilePicture,
        getAccountFriends,
        requestFriendship,
        decideFriendship,
        unfriend, 
        follow,
        unfollow
    }

}